/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
import React, {
	useEffect,
	useContext,
	useRef,
	useCallback,
	cloneElement,
	useLayoutEffect,
	useState,
} from 'react'
import { useApolloClient } from '@apollo/client'
import {
	DndContext,
	MouseSensor,
	TouchSensor,
	useSensors,
	useSensor,
	MeasuringStrategy,
	pointerWithin,
	rectIntersection,
} from '@dnd-kit/core'
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { useHistory, useParams } from 'react-router-dom'

import orderBy from 'lodash/orderBy'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useIdleTimer } from 'react-idle-timer'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import { userStateContext } from '@lynit/shared/src/state/userProvider'
import {
	beatsDataDispatchContext,
	beatsDataStateContext,
} from '@lynit/shared/src/state/beatsProvider'
import { systemDispatchContext, systemStateContext } from '@lynit/shared/src/state/systemProvider'
import {
	graphDataDispatchContext,
	setGraphData as sharedSetGraphData,
	resetGraphData as sharedResetGraphData,
	graphDataStateContext,
} from '@lynit/shared/src/state/graphDataProvider'
import {
	moveBeatConnection,
	reorderElement,
	setCurrentFlow,
	setCurrentStoryId,
	setDeepModeElement,
	setIsMobile,
	setLeftToggleTabs,
	setNewConnId,
	setNoElementError,
	setRightToggleTabs,
	setServerError,
	setUserSubscribed,
	setContextDeepModeConns,
	setSummaryCardVisible,
	setActiveConnections,
	setCurrentStory,
	setIsCatelogOpen,
} from '@lynit/shared/src/state/actions'
import useRecommendations from '@lynit/shared/src/hooks/useRecommendations'
import Navbar from '@lynit/shared/src/ui/Navbar'
import NoNetworkOverlay from '@lynit/layout/src/ui/NoNetworkOverlay'
import ElementList from '@lynit/layout/src/ui/ElementList'
import RightSidePanel from '@lynit/layout/src/ui/RightSidePanel'

import DragOverlayComponent from '@lynit/layout/src/ui/DragOverlay'
import { createNodeId, nodeTypeForId } from '@lynit/shared/src/utils/utils'
import getConnections from '@lynit/shared/src/utils/getConnections'
import {
	createCacheConnection,
	deleteCacheConnections,
	updateCacheOrderElement,
	updateCacheOrderBeat,
	moveBeatCacheUpdate,
	updateCacheField,
	parseGraphData,
} from '@lynit/shared/src/utils/apollo'

import { toastHandler } from '@lynit/shared/src/utils/backendHandler'
import splitter from '../../images/splitter.svg'
import welcomeVisualization from '@lynit/shared/src/images/supporting-structure-tag.svg'
//import welcomeVisualization from '../../images/welcome-visualization.svg'
import {
	useDeleteNodeMutation,
	useCreateConnectionMutation,
	useDeleteConnectionMutation,
	useGetRelationshipsQuery,
	useReorderBeatsMutation,
	useReorderNodesMutation,
	useMoveBeatMutation,
	useChangeIsFirstLoginMutation,
	useUpdateRelationshipMutation,
	useGetAllNodesQuery,
	useEditStoryMutation,
	useAddDriversToBeatsMutation,
	useLazyGetAllNodesQuery,
} from '@lynit/shared/src/hooks'
import { Card, Container, BaseContainer } from './styles'

//import introJs from 'intro.js'

//import 'intro.js/introjs.css'
import './tooltip.css'
import DeepModeElement from '@lynit/deep-mode-element/src/ui/DeepModeElement'
import StoryOutline from '@lynit/story-outline/src/ui/StoryOutline/StoryOutline'
import DriverDoc from '../DriverDoc/DriverDoc'
import WritingPlanner from '../WritingPlanner/WritingPlanner'
import ChapterDoc from '../ChapterDoc/ChapterDoc'
import PremisePlanner from '../PremisePlanner/PremisePlanner'
import OutliningPlanner from '../OutliningPlanner/OutliningPlanner'

const Layout = () => {
	const {
		state: {
			recommendationId,
			story,
			tutorialId,
			leftToggleTabs,
			rightToggleTabs,
			activeConnections,
			isActive,
			currentStory,
			cacheConnData: { newConnId },
		},
		dispatch: sharedDispatch,
	} = useContext(sharedContext)

	const types =
		currentStory?.lastDocView === 'Writing' ? ['Chapter'] : ['Arc', 'Character', 'Event', 'Theme']

	const initialVal = {
		active: currentStory?.lastDocView === 'Writing' ? types[0] : types[1],
		isMutatedConn: false,
		connectionModal: false,
		isChangeStoryModal: false,
		deepModeConnections: [],
		isDelete: false,
		filteredNodes: [],
		isDriver: null,
		driverConnToDelete: null,
		networkOverlay: false,
		isOnboardingDone: false,
		currentElementDel: {},
		chapterGridCol: '33% 50% 16%',
		isNewRecommendations: false,
		currentView: 'visualization',
		currentChapter: {},
		isExpandingView: false,
		recommendations: [],
		WTDNActiveLink: 'recommendation',
		previousCompletedRecommendations: null,
		previousStory: '',
		isUserNotActive: null,
		isChapterTabOpen: false,
	}

	const reduceFn = (state, action) => {
		return { ...state, [action.key]: action.value }
	}

	const { currentElement, currentStoryId, deepModeElement, serverError } =
		useContext(beatsDataStateContext)
	const { user, refetchUser } = useContext(userStateContext)
	const { isUserSubscribed, isOnline, createLog } = useContext(systemStateContext)

	const graphDataDispatch = useContext(graphDataDispatchContext)
	const systemDataDispatch = useContext(systemDispatchContext)
	const beatsDataDispatch = useContext(beatsDataDispatchContext)

	const history = useHistory()

	//const { data, loading: allNodesLoading, refetch, error } = useGetAllNodesQuery()
	const {
		data,
		loading: allNodesLoading,
		refetch,
		error,
	} = useGetAllNodesQuery({ fetchPolicy: 'network-only' })
	
	if (
		error?.message?.includes('Wrong storyId format') ||
		error?.message?.includes("StoryId Doesn't Exist")
	) {
		history.push('/dashboard')
	}

	const [graphData, setGraphDataLocal] = useState(
		parseGraphData({
			data: {
				nodes: data?.nodes,
				loading: allNodesLoading,
				refetch,
				error,
			},
		}),
	)
	//const graphData =
	//eslint-disable-next-line
	const [state, dispatch] = React.useReducer(reduceFn, initialVal)

	const client = useApolloClient()
	const { story: storyParams } = useParams()

	const {
		loading,
		data: relationships,
		refetch: refetchRelations,
	} = useGetRelationshipsQuery({ fetchPolicy: 'network-only' , component:"Layout"})

	const [changeIsFirstLogin] = useChangeIsFirstLoginMutation()

	const [performCreateConnectionMutation] = useCreateConnectionMutation({ ignoreResults: true })
	const [performDeleteConnectionMutation] = useDeleteConnectionMutation({ ignoreResults: true })
	const [performAddDriversToBeatsMutation] = useAddDriversToBeatsMutation({ ignoreResults: true }) 

	const [deleteNode, { data: deletedNode }] = useDeleteNodeMutation({ ignoreResults: true })
	const [reorderNodes] = useReorderNodesMutation()
	const [reorderBeats] = useReorderBeatsMutation()
	const [moveBeat] = useMoveBeatMutation({ ignoreResults: true })
	const [performUpdateRelationshipMutation] = useUpdateRelationshipMutation({ ignoreResults: true })
	const [editStory] = useEditStoryMutation({ ignoreResults: true })

	const [isSetModifiers, setIsSetModifiers] = useState(false)

	const isTablet = useMediaQuery('(min-width: 601px) and (max-width: 768px)')
	const { isMobile } = useContext(systemStateContext)

	const [stepsEnabled, setStepEnabled] = useState(false)

	const tourSteps = {}
	

	

	useLayoutEffect(() => {
		sharedDispatch(setCurrentFlow('My Stories'))
		if (user?.isFirstLogin) {
			changeIsFirstLogin()
		}
	}, [])

	// if story is available on params then story id sets in context
	useEffect(() => {
		if (storyParams && storyParams !== currentStoryId) {
			sessionStorage.setItem('story', storyParams)
			beatsDataDispatch(setCurrentStoryId(storyParams))
		}
	}, [])

	// useEffect(() => {
	// 	getallNodes()
	// }, [])
	

	useEffect(() => {
		if (
			!state.isUserNotActive &&
			isActive &&
			!(
				user?.subscriptionStatus?.status === 'trialing' &&
				!user?.subscriptionStatus?.subscriptionSchedule
			)
		) {
			dispatch({ key: 'isUserNotActive', value: true })
		} else if (state.isUserNotActive) {
			dispatch({ key: 'isUserNotActive', value: false })
		}
	}, [isActive])

	useEffect(() => {
		if (deepModeElement?.nodeType === 'Chapter') {
			dispatch({ key: 'currentChapter', value: graphData?.nodes[deepModeElement.id] })
		} else if (!deepModeElement) {
			dispatch({ key: 'currentChapter', value: {} })
		}
	}, [deepModeElement?.id])

	useEffect(() => {
		if (isMobile) {
			sharedDispatch(setRightToggleTabs(false))
			sharedDispatch(setLeftToggleTabs(false))
		}
	}, [isMobile])

	

	useEffect(() => {
		createLog(
			'Screen Size',
			`{"width":${window?.innerWidth},"height":${window?.innerHeight}}`,
			'ParentContainer',
			null,
		)
	}, [])

	useEffect(() => {
		if (state.isMutatedConn) {
			dispatch({ key: 'isMutatedConn', value: false })
		}
		if (currentElement) {
			dispatch({ key: 'isDeepMode', value: true })
			if (!loading && !state.isMutatedConn) {
				dispatch({
					key: 'deepModeConnections',
					value: getConnections(currentElement, relationships?.relations, graphData?.nodes),
				})
			}

			if (graphData?.nodes[currentElement]) {
				// dispatch({
				// 	key: 'filteredNodes',
				// 	value: filterGraphData(graphData?.nodes, currentElement),
				// })
				setContextDeepModeConns(
					getConnections(currentElement, relationships?.relations, graphData?.nodes),
				)
			}
		}
		if (state.isDeepMode && (!currentElement || !deepModeElement)) {
			dispatch({ key: 'isDeepMode', value: false })
			dispatch({ key: 'connectionModal', value: false })
		}
	}, [currentElement, deepModeElement, graphData, relationships])

	useEffect(() => {
		if (isTablet || isMobile) {
			if (activeConnections?.isActive && !!graphData?.allNodes?.nodes?.length) {
				dispatch({ key: 'chapterGridCol', value: '1fr 0fr' })
			} else {
				dispatch({ key: 'chapterGridCol', value: '0fr 1fr' })
			}
		} else {
			if (activeConnections?.isActive && !!graphData?.allNodes?.nodes?.length) {
				dispatch({ key: 'chapterGridCol', value: '1fr 1fr' })
			} else {
				dispatch({ key: 'chapterGridCol', value: '0fr 1fr' })
			}
		}
	}, [isTablet, isMobile, activeConnections?.isActive, !!graphData?.allNodes?.nodes?.length])

	// useEffect(() => {
	// 	if (rightToggleTabs && leftToggleTabs && isTablet) {
	// 		dispatch({ key: 'chapterGridCol', value: '300px calc(100% - 380px) 70px' })
	// 	} else if (rightToggleTabs && leftToggleTabs && !isTablet) {
	// 		dispatch({ key: 'chapterGridCol', value: '470px calc(100% - 900px) 420px' })
	// 	} else if (!rightToggleTabs && leftToggleTabs && isTablet) {
	// 		dispatch({ key: 'chapterGridCol', value: '0fr 1fr' })
	// 	} else if (!rightToggleTabs && leftToggleTabs && !isTablet) {
	// 		if (activeConnections?.isActive) {
	// 			dispatch({ key: 'chapterGridCol', value: '1fr 1fr' })
	// 		} else {
	// 			dispatch({ key: 'chapterGridCol', value: '0fr 1fr 0px' })
	// 		}
	// 	} else if (rightToggleTabs && !leftToggleTabs && isTablet) {
	// 		dispatch({ key: 'chapterGridCol', value: '200px calc(50% - 35px) calc(50% - 175px)' })
	// 	} else if (rightToggleTabs && !leftToggleTabs && !isTablet) {
	// 		dispatch({ key: 'chapterGridCol', value: '70px calc(100% - 500px) 420px' })
	// 	} else {
	// 		dispatch({ key: 'chapterGridCol', value: '75px calc(100% - 155px) 70px' })
	// 	}
	// }, [rightToggleTabs, leftToggleTabs, isTablet, activeConnections?.isActive])

	// useEffect(() => {
	// 	if ((isMobile || isTablet) && leftToggleTabs && rightToggleTabs) {
	// 		sharedDispatch(setRightToggleTabs(false))
	// 	}
	// }, [leftToggleTabs])

	// useEffect(() => {
	// 	if ((isMobile || isTablet) && rightToggleTabs && leftToggleTabs) {
	// 		sharedDispatch(setLeftToggleTabs(false))
	// 	}
	// }, [rightToggleTabs])

	useEffect(() => {
		let activeID
		const isPremiseElementId =  graphData?.nodes?.[activeConnections?.elementId]?.coreElement === 'true'
		
		const isChangedChapter =
			activeConnections?.isActive &&
			activeConnections?.elementId !== currentStory?.lastOpenedChapter &&
			activeConnections?.docType === 'Chapter'

		const isChangedDriver =
			activeConnections?.isActive &&
			activeConnections?.elementId !== currentStory?.lastOpenedDriver &&
			activeConnections?.docType === 'Driver' && !isPremiseElementId
		
		const isChangedPremiseDriver = activeConnections?.isActive &&
			activeConnections?.elementId !== currentStory?.lastOpenedPremiseDriver &&
			activeConnections?.docType === 'Driver' && isPremiseElementId

		const isChangedAppView = currentStory?.lastDocView !== activeConnections?.appView && currentStory?.lastDocView
		
		const isClosedDoc = !activeConnections?.elementId && activeConnections?.appView === currentStory?.lastDocView	&& activeConnections?.appView
		
		const driverDesignationChanged = (currentStory?.lastDocView === "Premise" && !isPremiseElementId && activeConnections?.elementId && activeConnections?.appView === "Premise") || (currentStory?.lastDocView === "Outlining" && isPremiseElementId && activeConnections?.elementId &&  activeConnections?.appView === "Outlining")
		// console.log("isPremiseElementId",isPremiseElementId)
		// console.log("isChangedChapter",isChangedChapter)
		// console.log("isChangedDriver",isChangedDriver)
		// console.log("isChangedPremiseDriver",isChangedPremiseDriver)
		// console.log("isChangedAppView",isChangedAppView)
		// console.log("isClosedDoc",isClosedDoc)
		// console.log("activeConnections",activeConnections)
		// console.log("currentStory",currentStory)
		// console.log("driverDesignationChanged",driverDesignationChanged)

		let input
		
		if( activeConnections.isMobileTempClose){
			
		}
		else if (driverDesignationChanged){

			sharedDispatch(
				setActiveConnections({
					isActive: true,
					elementId: activeConnections?.elementId ,
					elementType: activeConnections.elementType,
					docType: 'Driver',
					appView: isPremiseElementId? 'Premise' : 'Outlining',
				}),
			)

			input = {
				lastDocView: isPremiseElementId? 'Premise' : 'Outlining',
			}
			
			
			if (isPremiseElementId){
				input['lastOpenedPremiseDriver'] = activeConnections.elementId
				input['lastOpenedDriver'] = ''
			} else {
				input['lastOpenedPremiseDriver'] = ''
				input['lastOpenedDriver'] =  activeConnections.elementId
			}


			if(currentStory?.id){
				sharedDispatch(setCurrentStory({ ...currentStory, ...input }))
				editStory({
					variables: {
						id: currentStory?.id,
						input,
					},
				})

			}

		}
		else if (!activeConnections.isActive && !activeConnections.appView) {
		//User loads app => check currentStory for guidance of activeConnections and don't touch currentStory

			if ('Writing' === currentStory?.lastDocView) {
				activeID = currentStory?.lastOpenedChapter
				sharedDispatch(
					setActiveConnections({
						isActive: true,
						elementId: activeID,
						elementType: activeID ? 'Chapter' : null,
						docType: activeID ? 'Chapter' : 'Writing',
						appView: 'Writing',
					}),
				)
			} else if ('Outlining' === currentStory?.lastDocView) {
				activeID = currentStory?.lastOpenedDriver
				sharedDispatch(
					setActiveConnections({
						isActive: true,
						elementId: activeID,
						elementType: activeID ? nodeTypeForId(activeID) : null,
						docType: activeID ? 'Driver' : 'Outlining',
						appView: 'Outlining',
					}),
				)

				if(!activeID){
					systemDataDispatch(setSummaryCardVisible(false))
				}
			}
			else if ('Premise' === currentStory?.lastDocView || ! currentStory?.lastDocView ) {
				
				activeID = currentStory?.lastOpenedPremiseDriver
				//const isPremiseID = graphData?.nodes?.[activeID]?.coreElement ==="true"

				sharedDispatch(
					setActiveConnections({
						isActive: true,
						elementId: activeID,
						elementType: activeID ? nodeTypeForId(activeID) : null,
						docType: activeID ? 'Driver' : "Premise",
						appView: 'Premise',
					}),
				)

				if(!activeID){
					systemDataDispatch(setSummaryCardVisible("Premise"))
				}

			
			}

			

			
			
			
		} //User switches appView => check currentStory for guidance of activeConnections and update currentStory lastDocView
		//User close doc => change activeConnections to false and update currentStory lastOpenedChapter or Driver
		//user switches doc => change activeConnections and currentStory
		else if (isChangedAppView) {

			createLog(
				`Writing Stage Changed`,
				{newWritingStage:activeConnections?.appView, currentWritingStage:currentStory?.lastDocView},
				'Navbar',
				'Navigation Between Writing Stages',
			)
			if ('Writing' === activeConnections?.appView) {
				activeID = activeConnections.elementId //|| currentStory?.lastOpenedChapter
				sharedDispatch(
					setActiveConnections({
						isActive: true,
						elementId: activeID,
						elementType: activeID ? 'Chapter' : null,
						docType: activeID ? 'Chapter' : 'Writing',
						appView: 'Writing',
					}),
				)

			
			 }
			 else if ("Outlining" === activeConnections?.appView){
				//systemDataDispatch(setSummaryCardVisible(false))
				activeID = activeConnections.elementId //|| currentStory?.lastOpenedDriver

				
				
				if ( !isPremiseElementId ) {
					sharedDispatch(
						setActiveConnections({
							isActive: true,
							elementId: activeID,
							elementType: activeID ? nodeTypeForId(activeID) : null,
							docType: activeID ? 'Driver' : 'Outlining',
							appView: 'Outlining',
						}),
					)
				} 

				if(!activeID){
					systemDataDispatch(setSummaryCardVisible(false))
				}

			}
			else if ("Premise" === activeConnections?.appView){
				
				
				activeID = activeConnections.elementId //|| currentStory?.lastOpenedPremiseDriver
				//const isPremiseID = graphData?.nodes?.[activeID]?.coreElement ==="true"

				sharedDispatch(
					setActiveConnections({
						isActive: true,
						elementId: activeID,
						elementType: activeID ? nodeTypeForId(activeID) : null,
						docType: activeID ? 'Driver' : "Premise",
						appView: 'Premise',
					}),
				)

				if(!activeID){
					systemDataDispatch(setSummaryCardVisible("Premise"))
				}

			}
			sharedDispatch(setIsCatelogOpen(false))

			input = {
				lastDocView: activeConnections.appView,
			}
			if (isChangedChapter) {
				input['lastOpenedChapter'] = activeConnections.elementId
			}
			if (isChangedDriver) {
				input['lastOpenedDriver'] = activeConnections.elementId
				//systemDataDispatch(setSummaryCardVisible(false))
			}
			if (isChangedPremiseDriver){
				input['lastOpenedPremiseDriver'] = activeConnections.elementId

			}

			if(currentStory?.id){
				sharedDispatch(setCurrentStory({ ...currentStory, ...input }))
				editStory({
					variables: {
						id: currentStory?.id,
						input,
					},
				})

			}

		}else if((isClosedDoc) || (isChangedDriver || isChangedChapter || isChangedPremiseDriver )){
			input ={
				lastDocView: activeConnections.appView,
			}

			if (isChangedChapter || isClosedDoc === 'Writing') {
				input['lastOpenedChapter'] = isClosedDoc === 'Writing' ? '' : activeConnections.elementId
			}
			if (isChangedDriver || isClosedDoc === 'Outlining') {
				input['lastOpenedDriver'] = isClosedDoc === 'Outlining' ? '' : activeConnections.elementId
				//systemDataDispatch(setSummaryCardVisible(nodeTypeForId(activeConnections.elementId)))
			}

			if (isChangedPremiseDriver || isClosedDoc === 'Premise') {
				input['lastOpenedPremiseDriver'] = isClosedDoc === 'Premise' ? '' : activeConnections.elementId
				
			}

			if(!isClosedDoc){

				createLog(
					`Driver Doc Opened`,
					{newWritingStage:activeConnections?.appView, currentWritingStage:currentStory?.lastDocView, docType:activeConnections.docType },
					'Driver Doc',
					'',
				)
			}
			
			if(currentStory?.id){
				sharedDispatch(setCurrentStory({ ...currentStory, ...input }))
				editStory({
					variables: {
						id: currentStory?.id,
						input,
					},
				})

			}
			
		}
	}, [activeConnections, currentStory?.lastDocView, graphData?.nodes?.[activeConnections?.elementId]?.coreElement])

	const linkNodes = useCallback(
		(id, isStatic, structureTag = '') => {
			if (relationships?.relations.some(rel => rel.id.includes('temp')) || newConnId) {
				return false
			}

			const rpElement = currentElement || activeConnections?.elementId
			const hasConnectionBeenMade = relationships?.relations?.some(
				// eslint-disable-next-line array-callback-return
				el => {
					if (isStatic) {
						return (
							(el?.connectionType === 'Static' &&
								el?.sourceNode?.id === id &&
								el?.destNode?.id === rpElement) ||
							(el?.connectionType === 'Static' &&
								el?.sourceNode?.id === rpElement &&
								el?.destNode?.id === id)
						)
					}
				},
			)
			if (!hasConnectionBeenMade) {
				const tempId = createNodeId('Relationship')

				const newConnection = {
					id: tempId,
					description: '',
					arcStage: null,
					relName: 'RELATED',
					order: null,
					connectionType: isStatic ? 'Static' : 'Beat',
					structureTag,
					arc: null,
					sourceNode: {
						id: rpElement,
						__typename: graphData?.nodes[rpElement]?.__typename,
					},
					destNode: {
						id,
						__typename: graphData?.nodes[id]?.__typename,
					},
					__typename: 'Relationship',
					createdAt: new Date().toISOString(),
					updatedAt: new Date().toISOString(),
					name: null,
					beatsDriven: '0',
					sharedBeats: [],
					firstBeat: '',
				}
				sharedDispatch(setNewConnId(tempId))
				performCreateConnectionMutation({
					client,
					newConnection,
					user,
				})
			}
			if (hasConnectionBeenMade) {
				toastHandler('warning', 'Duplicated Connection')
			}
		},
		[
			relationships?.relations,
			graphData?.nodes,
			currentElement,
			activeConnections,
			user,
			newConnId,
		],
	)

	useEffect(() => {
		if (deletedNode?.deleteNode === currentElement) {
			dispatch({ key: 'isDeepMode', value: false })
			dispatch({ key: 'connectionModal', value: false })
		}
	}, [deletedNode])

	useEffect(() => {
		const graphData = parseGraphData({
			data: {
				nodes: data?.nodes,
				loading: allNodesLoading,
				refetch,
				error,
			},
		})
		setGraphDataLocal(graphData)
		graphDataDispatch(sharedSetGraphData(graphData))
	}, [data?.nodes])

	// useEffect(() => {
	// 	const relationships = relationships?.relations
		
	// 	graphDataDispatch(sharedSetRelationships(relationships))
	// }, [relationships?.relations])


	

	useEffect(() => {
		if (!localStorage.token) {
			return history.push('/login')
		}
	}, [localStorage.token])

	const reorderElements = async result => {
		let current = state.active
		if (result.type === 'chapter') {
			current = 'Chapter'
		}
		const currentElements = graphData?.nodesByType[current]
		const activeElements = currentElements?.map(element => {
			return { ...element, order: +element.order }
		})
		const filteringData = orderBy(activeElements, ['order'], ['asc'])
		if (result.destination.index === result.source.index) {
			return
		}
		const sourceIndex = result.source.index
		const orderOfSourceElement = filteringData[sourceIndex]?.order
		const destinationIndex = result.destination.index
		const orderOfDestinationElement = filteringData[destinationIndex]?.order
		// array contains elements whose order has been changed
		const existingOrders = []
		const mutationData = []
		// up direction drag
		if (orderOfSourceElement > orderOfDestinationElement) {
			filteringData.forEach(element => {
				if (element.order >= orderOfDestinationElement && element.order < orderOfSourceElement) {
					mutationData.push({
						id: element.id,
						order: String(element.order + 1),
					})
					existingOrders.push({
						id: element.id,
						order: String(element.order),
					})
				}
				if (element.order === orderOfSourceElement) {
					mutationData.push({
						id: element.id,
						order: String(orderOfDestinationElement),
					})
					existingOrders.push({
						id: element.id,
						order: String(element.order),
					})
				}
			})
		}
		//down direction drag
		if (orderOfSourceElement < orderOfDestinationElement) {
			filteringData.forEach(element => {
				if (element.order > orderOfSourceElement && element.order <= orderOfDestinationElement) {
					mutationData.push({
						id: element.id,
						order: String(element.order - 1),
					})
					existingOrders.push({
						id: element.id,
						order: String(element.order),
					})
				}
				if (element.order === orderOfSourceElement) {
					mutationData.push({
						id: element.id,
						order: String(orderOfDestinationElement),
					})
					existingOrders.push({
						id: element.id,
						order: String(element.order),
					})
				}
			})
		}
		createLog(
			'Nodes Reorder Attempted',
			`{"elementType":"${current}"}`,
			'ElementList',
			'Nodes Reorder',
		)
		if (mutationData.length) {
			updateCacheOrderElement(client, mutationData)
			beatsDataDispatch(reorderElement())
			reorderNodes({
				variables: {
					nodeType: current,
					newNodeOrder: mutationData,
				},
			})
				.then(res => {
					//updateCacheOrderElement(client, mutationData)
					//beatsDataDispatch(reorderElement())
					if (res && serverError) {
						beatsDataDispatch(setServerError(false))
					}
				})
				.catch(error => {
					toastHandler('error', 'Error while updating an order. Try again.')
					refetchRelations()
					console.error(error)
					updateCacheOrderElement(client, existingOrders)
				})
		}
	}

	const pauseFunction = async time => {
		await new Promise(resolve => setTimeout(resolve, time))
	}
	const moveBeatHandler = async (oldChapterId, newChapterId, beatId, beatNewOrder) => {
		const oldChapter = graphData?.nodes[oldChapterId]
		const newChapter = graphData?.nodes[newChapterId]
		const beat = graphData?.nodes[beatId]

		const getConnectionTypeField = (type) => {
			
			if(type === "Relationship"){
				return "sharedBeats"
			} else if(type==="Chapter"){
				return "beatConnections"
			} else{
				return "driverConnections"
			}
		}

		const mutationData = []
		const existingOrders = []
		const relId = createNodeId('Relationship')
		const tempData = {
			newRelationship: {
				__typename: 'Relationship',
				id: relId,
				arcStage: null,
				beatsDriven: null,
				connectionType: newChapter?.__typename === "Chapter" ? 'Beat' : "Driver",
				description: '',
				destNode: { id: newChapter?.id, __typename: newChapter?.__typename },
				order: beatNewOrder,
				relName: 'RELATED',
				sourceNode: { id: beatId, __typename: 'Beat' },
				structureTag: null,
				arc: null,
				createdAt: new Date().toISOString(),
				updatedAt: new Date().toISOString(),
				name: null,
				sharedBeats: null,
				firstBeat: '',
			},
		}

		oldChapter?.[getConnectionTypeField(nodeTypeForId(oldChapterId))]
			?.filter(conn => ![conn.sourceNode.id, conn.destNode.id].includes(beat.id))
			.sort((a, b) => {
				return parseInt(a.order) - parseInt(b.order)
			})
			.forEach((beatConnection, index) => {
				const bId = beatConnection?.sourceNode?.id.startsWith('bea')
					? beatConnection?.sourceNode?.id
					: beatConnection.destNode.id

				mutationData.push({
					id: bId,
					connectionId:beatConnection.id,
					order: String(index),
					chapter: 'old',
					description: graphData?.nodes[bId].description,
				})

				existingOrders.push({
					id: beatConnection.id,
					order: String(beatConnection.order),
					chapter: 'old',
					description: graphData?.nodes[bId].description,
				})
			})

		newChapter?.[getConnectionTypeField(nodeTypeForId(newChapterId))]
			?.slice()
			?.sort((a, b) => {
				return parseInt(a.order) - parseInt(b.order)
			})
			.forEach((beatConnection, index) => {
				const bId = beatConnection?.sourceNode?.id.startsWith('bea')
					? beatConnection?.sourceNode?.id
					: beatConnection.destNode.id
				if (index >= +beatNewOrder) {
					mutationData.push({
						id: bId,
						connectionId:beatConnection.id,
						order: String(index + 1),
						chapter: 'new',
						description: graphData?.nodes[bId].description,
					})
				} else {
					mutationData.push({
						id: bId,
						connectionId:beatConnection.id,
						order: String(index),
						chapter: 'new',
						description: graphData?.nodes[bId].description,
					})
				}
				existingOrders.push({
					id: beatConnection.id,
					order: String(beatConnection.order),
					chapter: 'new',
					description: graphData?.nodes[bId].description,
				})
			})


		if ( newChapterId && beatId && beatNewOrder && beat) {

			await moveBeatCacheUpdate(
				client,
				oldChapterId ? beat?.beatConnections?.[0]:null,
				mutationData,
				tempData?.newRelationship,
				true,
			)
			moveBeat({
				variables: {
					input: {
						relId,
						oldChapterId,
						newChapterId,
						beatId,
						beatNewOrder,
					},
				},
				ignoreResults: true,
			}).catch(error => {
				deleteCacheConnections(client, { ...tempData?.newRelationship, arc: null }, false)
				createCacheConnection(client, { ...beat?.beatConnections?.[0], arc: null }, false, true)
				console.error(error)
				toastHandler('error', 'There was an error moving a beat, try again.')
				//refetchRelations()
				if (existingOrders?.length) {
					updateCacheOrderBeat(client, existingOrders)
				}
			})
		}
	}

	const handleReorderOfBeats = async (referenceElement, sourceIndex, destinationIndex, connectionTypeField) => {
		let connections = []

		connections = referenceElement?.[connectionTypeField]?.map(conn => {
			return { ...conn, order: +conn.order }
		})
		
		const orderedConnections = orderBy(connections, ['order'], ['asc']).map((conn, index) => {
			conn.order = String(index)
			return conn
		})
		const orderOfSourceElement = orderedConnections[sourceIndex]?.order
		const orderOfDestinationElement = orderedConnections[destinationIndex]?.order

		// array contains elements whose order has been changed
		const mutationData = []
		const existingOrders = []

		// up direction drag
		if (orderOfSourceElement > orderOfDestinationElement) {
			orderedConnections.forEach((connection, index) => {
				if (
					connection.order >= orderOfDestinationElement &&
					connection.order < orderOfSourceElement
				) {
					mutationData.push({
						id: connection.id,
						order: String(+connection.order + 1),
					})
					existingOrders.push({
						id: connection.id,
						order: String(connection.order),
					})
				} else if (connection.order === orderOfSourceElement) {
					mutationData.push({
						id: connection.id,
						order: String(orderOfDestinationElement),
					})
					existingOrders.push({
						id: connection.id,
						order: String(connection.order),
					})
				} else {
					mutationData.push({
						id: connection.id,
						order: String(index),
					})
					existingOrders.push({
						id: connection.id,
						order: String(connection.order),
					})
				}
			})
		}

		//down direction drag
		if (orderOfSourceElement < orderOfDestinationElement) {
			orderedConnections.forEach((connection, index) => {
				if (connection.order === orderOfSourceElement) {
					mutationData.push({
						id: connection.id,
						order: String(orderOfDestinationElement),
					})
					existingOrders.push({
						id: connection.id,
						order: String(connection.order),
					})
				} else if (
					connection.order > orderOfSourceElement &&
					connection.order <= orderOfDestinationElement
				) {
					mutationData.push({
						id: connection.id,
						order: String(+connection.order - 1),
					})
					existingOrders.push({
						id: connection.id,
						order: String(connection.order),
					})
				} else {
					mutationData.push({
						id: connection.id,
						order: String(index),
					})
					existingOrders.push({
						id: connection.id,
						order: String(connection.order),
					})
				}
			})
		}

		if (mutationData.length) {
			updateCacheOrderBeat(client, mutationData)
			reorderBeats({
				variables: {
					referenceElementId: referenceElement?.id,
					newNodeOrder: mutationData,
					connectionType: referenceElement?.[connectionTypeField]?.[0].connectionType,
				},
				ignoreResults: true,
			})
				.then(res => {
					if (res && serverError) {
						beatsDataDispatch(setServerError(false))
					}
				})
				.catch(err => {
					toastHandler('error', 'There was an error updating order of a beat, try again.')
					refetchRelations()
					console.error(err)
					updateCacheOrderBeat(client, existingOrders)
				})
		}
	}

	const removeBeatConnection = async (beatConnection, referenceElement) => {
		try {
			if (!beatConnection?.id?.includes('temp')) {
				const mutationData = []
				referenceElement?.beatConnections.forEach(connection => {
					if (+connection.order > +beatConnection.order) {
						mutationData.push({
							id: connection.id,
							order: String(+connection.order - 1),
						})
					}
				})

				await performDeleteConnectionMutation({
					id: beatConnection.id,
					client,
					elementToDelete: beatConnection,
					mutationData,
				})
			}
		} catch (error) {
			console.error(error)
			toastHandler('error', 'Error while deleting a beat connection. Try again.')
		}
	}

	//const gutter = document.getElementsByClassName('gutter')[0]

	// useEffect(() => {
	// 	if (gutter?.childElementCount === 0) {
	// 		const div = document.createElement('div')
	// 		div.className = 'splitter'
	// 		const image = document.createElement('img')
	// 		image.setAttribute('src', splitter)
	// 		image.setAttribute('alt', 'splitter icon')
	// 		div.append(image)
	// 		gutter.append(div)
	// 	}
	// }, [deepModeElement?.nodeType === 'Chapter', gutter])

	const sensors = useSensors(
		useSensor(MouseSensor, {
			activationConstraint: {
				distance: 10,
			},
		}),
		useSensor(TouchSensor, {
			activationConstraint: {
				delay: 250,
				tolerance: 20,
			},
		}),
	)

	const handleDragEnd = async ({ active, over }) => {
		if (isUserSubscribed) {
			systemDataDispatch(setUserSubscribed(null))
		}
		if (
			!over ||
			over?.data?.current?.id === 'view-container' ||
			over?.data?.current?.id === 'document-container' ||
			(active?.data?.current?.type !== over?.data?.current?.type && active?.data.current?.type)
		) {
			return
		}
		const { type } = over?.data?.current

		let activeBeat
		let activeBeatSourceElement
		let destElement
		let destElementBeatList
		let connectionTypeField
		let activeBeatSourceElementBeatList
		let activeBeatConnectiontypeField
		
		if (type === 'beat') {
			
			destElement = over?.data?.current?.referenceElement?.id?.startsWith('rel') ?
				relationships?.relations?.find(relation => relation?.id === over?.data?.current?.referenceElement?.id)
			 	: graphData?.nodes[over?.data?.current?.referenceElement?.id]


			connectionTypeField = over?.data?.current.connectionTypeField 
			activeBeatConnectiontypeField = active?.data?.current?.connectionTypeField 

			destElementBeatList = destElement?.__typename === "Relationship" ?
			relationships?.relations?.find(relation => relation?.id === destElement?.id)?.["sharedBeats"]?.map((beat,index)=> {return {...beat,order:index}})  
			: graphData?.nodes[destElement.id]?.[connectionTypeField]?.map((connection) => {
				const beatId = connection?.sourceNode?.id?.startsWith('bea')
									? connection?.sourceNode?.id
									: connection?.destNode?.id
				
				return { id:beatId,connectionId:connection.id, order: +connection.order }
			})

			activeBeat = graphData?.nodes[active?.data?.current.id] || graphData?.nodes[active?.id?.slice(0,40)]
			activeBeatSourceElement =  active?.data?.current?.referenceElement?.id.startsWith('rel') ?
				relationships?.relations?.find(relation => relation?.id === active?.data?.current.referenceElement?.id)
				: graphData?.nodes[active?.data?.current.referenceElement?.id]

			activeBeatSourceElementBeatList = activeBeatSourceElement?.__typename === "Relationship" ?
			relationships?.relations?.find(relation => relation?.id === activeBeatSourceElement?.id)?.["sharedBeats"].map((beat,index)=> {return {...beat,order:index}})  
			: graphData?.nodes[activeBeatSourceElement?.id]?.[activeBeatConnectiontypeField]?.map((connection) => {
				const beatId = connection?.sourceNode?.id?.startsWith('bea')
									? connection?.sourceNode?.id
									: connection?.destNode?.id
				return { id:beatId,connectionId:connection.id, order: +connection.order }
			
			})
			
			
		}

		const destIndex = over?.data?.current?.id?.includes('empty')
			? 0
			: over?.data?.current?.id?.startsWith('container')
			? activeBeatSourceElement?.id === destElement?.id
				? destElementBeatList?.length - 1
				: destElementBeatList?.length
			: over?.data?.current?.sortable?.index

		// handle reorder of beat or element
		if (
			active?.data?.current?.sortable?.containerId === over?.data?.current?.sortable?.containerId &&
			active?.data?.current?.sortable?.index !== over?.data?.current?.sortable?.index
		) {
			const sourceIndex = active?.data?.current?.sortable?.index
			const destIndex = over?.data?.current?.sortable?.index
			const result = {
				referenceElement: type === 'beat' ? active?.data?.current?.referenceElement : null,
				type,
				source: {
					index: sourceIndex,
				},
				destination: {
					index: destIndex,
				},
			}

			beatsDataDispatch(reorderElement(result))
			if (type === 'beat' && activeBeatSourceElement?.id === over?.data?.current?.referenceElement?.id) {
				if (over?.data.current.referenceElement?.__typename === 'Relationship') {
					const beatBreakdownConnection = relationships?.relations?.find(
						relation => relation?.id === over?.data?.current?.locationId,
					)
					const sharedBeats = beatBreakdownConnection?.sharedBeats?.map(beat => beat)
					sharedBeats?.move(sourceIndex, destIndex)
					const staticConnectionUpdateFields = { sharedBeats, updatedAt: new Date().toISOString() }

					performUpdateRelationshipMutation({
						id: over?.data?.current?.locationId,
						input: {
							sharedBeats: staticConnectionUpdateFields?.sharedBeats?.map(beat => {
								return { id: beat?.id }
							}),
						},
						client,
						__typename: 'Relationship',
						newMapValue: staticConnectionUpdateFields,
					})
				} else {
					
					const orderedSourceConnections = orderBy(activeBeatSourceElementBeatList, ['order'], ['asc'])
					const sourceIndex = orderedSourceConnections.findIndex(
						connection => connection?.id === activeBeat?.id,
					)

					if (sourceIndex !== destIndex) {
						handleReorderOfBeats(activeBeatSourceElement, sourceIndex, destIndex, connectionTypeField)
					}
				}
			} else if (type !== 'beat') {
				reorderElements(result)
			}
		} 
		// else if (type === 'beat' && activeBeatSourceElement?.id === destElement?.id) {
			
			
			
		// 	const orderedSourceConnections = orderBy(activeBeatSourceElementBeatList, ['order'], ['asc'])
		// 	const sourceIndex = orderedSourceConnections.findIndex(
		// 		connection => connection?.id === activeBeat?.id,
		// 	)
		// 	if (sourceIndex !== destIndex) {
		// 		handleReorderOfBeats(activeBeatSourceElement, sourceIndex, destIndex)
		// 	}
		// }

		if(destElementBeatList?.find(beat => beat?.id === activeBeat?.id)){
			// skip if beat already exists in destination
			return
		}
		
		if (
			type === 'beat' &&
			(over?.data?.current?.id?.includes('empty') ||
				over?.data?.current?.id?.startsWith('container') || true
				)
		) {
			if (
				over?.data?.current?.id?.startsWith('container') &&
				destElementBeatList?.length === 0
			) {
				return
			}
			
			if (activeBeatSourceElement?.id !== destElement.id) {
				//if dropping beat in a different element
				
				const beatId = activeBeat?.id
				const destinationConnections = destElementBeatList
				const orderedDestinationConnections = orderBy(destinationConnections, ['order'], ['asc'])
				const orderOfDestinationElement =
					destIndex === 0
						? 0
						: destIndex === orderedDestinationConnections?.length
						? orderedDestinationConnections[destIndex - 1]?.order + 1
						: orderedDestinationConnections[destIndex]?.order
				
				const beat = graphData?.nodes[beatId]
				
				//Beat needs to be moved between chapters
				
				if ((beat?.beatConnections?.length === 1 ) &&  destElement?.__typename === "Chapter" ) {
					await moveBeatHandler(
						beat?.beatConnections?.[0].sourceNode.__typename === "Beat" ? beat?.beatConnections?.[0]?.destNode?.id :beat?.beatConnections?.[0]?.sourceNode?.id ,
						destElement?.id,
						beatId,
						String(orderOfDestinationElement),
					)
				} 
				else if ((activeBeatSourceElement?.__typename !== 'Chapter' || destElement?.__typename !=="Chapter" )) {
					if (isUserSubscribed) {
						return
					}
					// array contains elements whose order has been changed
					const destinationMutationData = []
					const existingOrders = []
					orderedDestinationConnections?.forEach(beat => {
						if (beat.order >= orderOfDestinationElement) {
							destinationMutationData.push({
								id: beat.id,
								connectionId:beat.connectionId,
								order: String(+beat.order + 1),
							})
							existingOrders.push({
								id: beat.id,
								order: String(beat.order),
							})
						}
					})

					try {
						

						const oldChapterId = beat?.beatConnections?.[0]?.destNode?.id?.startsWith('bea')
							? beat?.beatConnections?.[0]?.sourceNode?.id
							: beat?.beatConnections?.[0]?.destNode?.id

						// if user try to put beat into the same chapter
						if (oldChapterId === destElement.id) {
							return
						}

						// beat needs to be added to a chapter for first time
						if (beat?.beatConnections?.length === 0 && destElement?.__typename ==="Chapter") {
							// if beat is not already in another chapter
							
							await moveBeatHandler(
								null,
								destElement.id,
								beatId,
								String(orderOfDestinationElement),
							)

						} 
						// beats need to be added to driver or relationship
						else  {


							const newStaticConnections = []
							const existingStaticConnections = []
							const newDrivers = [] 
							 if (connectionTypeField ==="sharedBeats"){
								const destElementSourceNodeHasBeatAlready = activeBeat.driverConnections.find(conn => [conn.destNode.id,conn.sourceNode.id].includes(destElement.sourceNode.id))
								
								if(!destElementSourceNodeHasBeatAlready){
									newDrivers.push({id: destElement.sourceNode.id,
										__typename: destElement.sourceNode.__typename,
										order: String(graphData?.nodes?.[destElement.sourceNode.id].driverConnections.length)})
								
								}
								const destElementDestNodeHasBeatAlready = activeBeat.driverConnections.find(conn => [conn.destNode.id,conn.sourceNode.id].includes(destElement.destNode.id))
								
								if(!destElementDestNodeHasBeatAlready){
									newDrivers.push({id: destElement.destNode.id,
										__typename: destElement.destNode.__typename,
										order: String(graphData?.nodes?.[destElement.destNode.id].driverConnections.length)})
								
								}

							 } else if (connectionTypeField === "premiseConnections") {

								const newConnection = {
									id: createNodeId('Relationship'),
									description: '',
									arcStage: null,
									relName: 'RELATED',
									order: String(orderOfDestinationElement),
									connectionType: 'Premise',
									structureTag: null,
									arc: null,
									sourceNode: {
										id: activeBeat?.id,
										__typename: 'Beat',
									},
									destNode: destElement,
									__typename: 'Relationship',
									createdAt: new Date().toISOString(),
									updatedAt: new Date().toISOString(),
									name: null,
									beatsDriven: '0',
									sharedBeats: null,
									firstBeat: '',
								}


								performCreateConnectionMutation({
									client,
									newConnection,
									user,
									existingOrders,
									moveBeatCacheUpdateData: destinationMutationData
								})

							 }
							 else {
								newDrivers.push({id: destElement.id,
										 __typename: destElement.__typename,
										 order: String(orderOfDestinationElement)})
							 }

							const addedDriverConnections = newDrivers.map((driver, index) => {
								return {
									id: createNodeId('Relationship'),
									description: '',
									arcStage: null,
									relName: 'RELATED',
									order: driver.order,
									connectionType: 'Driver',
									structureTag: null,
									arc: null,
									sourceNode: {
										id: activeBeat?.id,
										__typename: 'Beat',
									},
									destNode: {
										id:driver.id,
										__typename: driver.__typename
									},
									__typename: 'Relationship',
									createdAt: new Date().toISOString(),
									updatedAt: new Date().toISOString(),
									name: null,
									beatsDriven: '0',
									sharedBeats: null,
									firstBeat: '',
								}
							})
							
							const fullSetOfDrivers = activeBeat.driverConnections.map(conn => {
									return conn?.destNode?.id?.startsWith('bea')
								? conn?.sourceNode
								: conn?.destNode
								}).concat(newDrivers.map(driver => { return {id: driver.id, __typename:driver.__typename}}))

							const setUniqueStaticConnections = new Set()
							addedDriverConnections.map((driver, index) => {
								const driver_A = driver.destNode
								fullSetOfDrivers.map(driver_B => {
									const driverPair = [driver_A.id, driver_B.id].sort().toString()
									if (setUniqueStaticConnections.has(driverPair) || driver_A.id === driver_B.id) {
										return
									}

									setUniqueStaticConnections.add(driverPair)

									const existStaticConnection = graphData.nodes[driver_A.id]?.staticConnections?.find(
										conn => {
											return [conn.sourceNode.id, conn.destNode.id].includes(driver_B.id)
										},
									)
									if (!existStaticConnection) {
										const sharedBeats = graphData.nodes[driver_A.id].driverConnections
											.map(conn => {
												const beatId =
													conn.sourceNode.__typename === 'Beat' ? conn.sourceNode.id : conn.destNode.id
												const beat = graphData.nodes[beatId]
												return beat
											})
											.filter(beat => {
												const sharedBeat = beat.driverConnections.find(driver => {
													return [driver.sourceNode.id, driver.destNode.id].includes(driver_B.id)
												})
												return sharedBeat
											})
											.map(beat => beat)
										sharedBeats?.push(beat)

										newStaticConnections.push({
											id: createNodeId('Relationship'),
											description: null,
											arcStage: null,
											relName: 'RELATED',
											order: String(index),
											connectionType: 'Static',
											structureTag: null,
											arc: null,
											sourceNode: driver_A,
											destNode: driver_B,
											__typename: 'Relationship',
											// createdAt: new Date().toISOString(),
											// updatedAt: new Date().toISOString(),
											//name: null,
											beatsDriven: String(sharedBeats?.length),
											sharedBeats,
											firstBeat: '',
											createdAt: new Date().toISOString(),
											updatedAt: new Date().toISOString()
										})
									} else {
										let newSharedBeats 
										if (existStaticConnection.id === destElement.id){
											newSharedBeats = [...destElementBeatList.slice(0,orderOfDestinationElement),activeBeat,...destElementBeatList.slice(orderOfDestinationElement)].map(beat=>  { return {id:beat?.id}})

										} else {
											newSharedBeats = (existStaticConnection.sharedBeats || []).concat([activeBeat])
										}
										const newBeatsDriven = String(newSharedBeats.length)
										existingStaticConnections.push({
											...existStaticConnection,
											sharedBeats: newSharedBeats,
											beatsDriven: newBeatsDriven,
										})
									}
								})
							})
							
							await performAddDriversToBeatsMutation({
								removedDriverConnections:[],
								addedDriverConnections,
								newStaticConnections,
								existingStaticConnections,
								client,
								sourceId:activeBeat.id,
								beat:activeBeat,
								destinationBeatsToReorder:destinationMutationData,
							})

							
							
						}
						
					} catch (err) {
						console.error(err)
						refetchRelations()
						if (err.message.includes('Cannot update')) {
							beatsDataDispatch(setNoElementError(true))
						}
						if (err.message.includes('Failed to fetch') || err.message.includes('NetworkError')) {
							beatsDataDispatch(setServerError(true))
						}
					}
				}
			}
		}

		createLog(
			'Drag End',
			`{"activeBeatSourceElementType":"${activeBeatSourceElement?.__typename}","type":"${type}"}`,
			'Story Outline',
			'Drag and Drop',
		)
	}
	useEffect(() => {
		if (activeConnections?.appView) {
			const elem = document.getElementById(`elementList`)
			elem?.scrollTo({
				top: elem?.scrollTo(0, 0),
				behavior: 'smooth',
			})
		}
	}, [activeConnections])

	const handleDragOver = ({ active, over }) => {
		const chapterViewHovered =
			over?.data?.current?.referenceElement?.id?.startsWith('chp') ||
			over?.data?.current?.id?.includes('chp')

		if (isUserSubscribed && !chapterViewHovered) {
			systemDataDispatch(setUserSubscribed(null))
		}
		if (
			over?.data?.current?.id === 'view-container' ||
			over?.data?.current?.id === 'document-container'
		) {
			return
		}
		const activeRelation = relationships?.relations?.find(
			relation => relation?.id === active?.data?.current?.connectionId,
		)
		const totalBeatConnectionCount = graphData?.nodesByType.Chapter.reduce(
			(acc, chapter) => acc + chapter?.beatConnections.length,
			0,
		)
		if (
			!isUserSubscribed &&
			activeRelation?.connectionType === 'Driver' &&
			isActive &&
			chapterViewHovered &&
			!isUserSubscribed?.isActiveUser &&
			totalBeatConnectionCount >= 20
		) {
			systemDataDispatch(setUserSubscribed({ isActiveUser: true }))
		}
	}

	const customCollisionDetectionAlgorithm = args => {
		const pointerCollisions = pointerWithin(args)
		if (pointerCollisions?.length > 0) {
			return pointerCollisions
		}
		return rectIntersection(args)
	}

	const { refetchRecommendations } = useRecommendations()

	const nIntervId = useRef()

	const onActive = () => {
		clearInterval(nIntervId.current)
		nIntervId.current = setInterval(() => {
			refetchRecommendations()
		}, 30000)
	}

	useEffect(() => {
		//onActive()
		return () => {
			//clearInterval(nIntervId.current)
			graphDataDispatch(sharedResetGraphData())
		}
	}, [])

	const onIdle = () => {
		clearInterval(nIntervId.current)
	}

	//useIdleTimer({ timeout: 60000, onIdle, onActive })
	return (
		<>
			<Container>
				<Navbar createLog={createLog} isDashboard={false} isLayout dispatch={dispatch} />
				<DndContext
					sensors={sensors}
					measuring={{
						droppable: {
							strategy: MeasuringStrategy.Always,
						},
					}}
					modifiers={isSetModifiers && [restrictToParentElement, restrictToVerticalAxis]}
					onDragEnd={handleDragEnd}
					onDragOver={handleDragOver}
					collisionDetection={customCollisionDetectionAlgorithm}
				>
					<DragOverlayComponent
						relationships={relationships}
						// isDeepMode={!!deepModeElement}
						isSetModifiers={isSetModifiers}
					/>
					<BaseContainer
						className="elementList1"
						windowHeight={window?.innerHeight}
						// isDeepMode={!!deepModeElement}
						chapterGridCol={state.chapterGridCol}
						chapterDeepDiveMode={state.chapterDeepDiveMode}
						leftToggleTabs={leftToggleTabs}
						rightToggleTabs={rightToggleTabs}
						id="baseContainer"
					>
						{/* <Card
							gridColumn="3/4"
							gridRow="1/3"
							gridMobColumn={rightToggleTabs ? '2/4' : '3/4'}
							gridMobRow="1/3"
							// isDeepMode={!!deepModeElement}
						>
							<RightSidePanel
								id="rightSidePanel"
								linkNodes={linkNodes}
								isChapterTabOpen={state.isChapterTabOpen}
							/>
						</Card> */}

						{/* element list */}
						{(<Card
							gridColumn="1"
							gridRow="1/3"
							gridMobColumn={'1/2'}
							gridMobRow="1/3"
							visible={activeConnections?.isActive && !graphData.loading}
							id='elementList'
						>
							{
								activeConnections?.docType === 'Driver' &&
								activeConnections.elementId && (
									<DriverDoc
										id={activeConnections.elementId}
										className="elementList"
										setActive={val => dispatch({ key: 'active', value: val })}
										active={state?.active}
										types={types}
										currentView={state.currentView}
										dispatch={dispatch}
										connectionModal={state.connectionModal}
										setConnectionModal={val => dispatch({ key: 'connectionModal', value: val })}
										structureTagConnnectionModal={state.structureTagConnnectionModal}
										setStructureTagConnnectionModal={val =>
											dispatch({ key: 'structureTagConnnectionModal', value: val })
										}
										setIsSetModifiers={setIsSetModifiers}
										state={state}
										linkNodes={linkNodes}
										deletedNode={deletedNode}
										
									/>
								)}
							{activeConnections?.appView === 'Premise' && !activeConnections.elementId && (
								<PremisePlanner className="elementList" />
							)}
							{activeConnections?.appView === 'Outlining' && !activeConnections.elementId && (
								<OutliningPlanner className="elementList" />
							)}
							{activeConnections?.appView === 'Writing' && !activeConnections.elementId && (
								<WritingPlanner className="elementList" />
							)}
							{activeConnections?.appView === 'Writing' && activeConnections.elementId && (
								<ChapterDoc className="elementList" id={activeConnections.elementId} />
							)}

						</Card>)}

						{/* {deepModeElement ? (
							<DeepModeElement
								state={state}
								dispatch={dispatch}
								linkNodes={linkNodes}
								deletedNode={deletedNode}
							/>
						) : ( */}
						<StoryOutline
							state={state}
							dispatch={dispatch}
							linkNodes={linkNodes}
							deletedNode={deletedNode}
							
						/>
						{/* )}  */}
						{/* {cloneElement(children, {
							state,
							dispatch,
							linkNodes,
							deletedNode,
							response,
						})} */}
					</BaseContainer>
				</DndContext>
			</Container>
			<NoNetworkOverlay
				isShowed={state.networkOverlay}
				setIsShowed={val => dispatch({ key: 'networkOverlay', value: val })}
				isOnline={isOnline}
			/>
		</>
	)
}

export default Layout
