import React from 'react'
import ReactGA from 'react-ga'
import LoadingSpinner from '@lynit/shared/src/shared-ui/LoadingSpinner'
import FallbackUI from './FallbackUI'
import { AppContext } from '../context/AppContext'

const TRACKING_ID = 'UA-172831121-1' // YOUR_OWN_TRACKING_ID
class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = { hasError: false, hasChunkLoadFailedError: false }
		ReactGA.initialize(TRACKING_ID)
	}

	static getDerivedStateFromError(error) {
		const chunkFailedMessage = /Loading chunk [\d]+ failed/
		if (chunkFailedMessage.test(error.message)) {
			setTimeout(() => {
				window.location.reload()
			}, 500)
			return { hasChunkLoadFailedError: true }
		}
		// Update state so the next render will show the fallback UI.
		return { hasError: true }
	}

	componentDidCatch(error, info) {
		const errorObj = {
			errorMessage: `${error.message}`,
			errorStack: `${error.stack}`,
			componentStack: `${info.componentStack}`,
		}
		this.context.setEBoundariesError(errorObj)
	}

	render() {
		if (this.state.hasChunkLoadFailedError) {
			return <LoadingSpinner />
		}
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <FallbackUI />
		}

		return this.props.children
	}
}
ErrorBoundary.contextType = AppContext

export default ErrorBoundary
