import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useApolloClient, useMutation } from '@apollo/client'
import {
	deleteCacheConnections,
	deleteCacheElement,
	updateCacheField,
	updateCacheOrderBeat,
} from '@lynit/shared/src/utils/apollo'
import whitePlusIconYellowBackground from '@lynit/shared/src/images/whitePlusIconYellowBackground.svg'
import {
	createNodeId,
	getNodeIcon,
	structureTagMatcher,
	vizTitleLengthFormat,
} from '@lynit/shared/src/utils/utils'
import supportingConnectionIcon from '@lynit/shared/src/images/supporting-connection-structure-tag.svg'
import opposingConnectionIcon from '@lynit/shared/src/images/opposing-connection-structure-tag.svg'
import deleteIcon from '@lynit/shared/src/images/deleteIcon.svg'
import {
	BeatsCountLabel,
	BeatsTextWrapper,
	Connection,
	ConnectionCardContainer,
	ConnectionDescription,
	ConnectionImage,
	ElementCardContainer,
	ElementName,
	ElementNameLable,
	IconContainer,
	NewConnectionWrapper,
	StructureTagLable,
	TooltipDescription,
	TooltipWrapper,
} from './styles'
import {
	useCreateBeatMutation,
	useCreateConnectionMutation,
	useUpdateRelationshipMutation,
	useCreateSharedBeatMutation,
	useDeleteConnectionMutation,
	useGetRelationshipsQuery,
	useReorderBeatsMutation,
	useDraggable,
} from '@lynit/shared/src/hooks'
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import { makeStyles } from '@mui/styles'
import { nodeColors } from '@lynit/shared/src/utils/commonStyles'
import useQuillAutoSave from '@lynit/shared/src/ui/AutoSave/useQuillAutoSave'

import DescriptionField from '@lynit/shared/src/ui/DescriptionField'
import { beatsDataStateContext } from '@lynit/shared/src/state/beatsProvider'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'
import SuggestionTooltip from '@lynit/shared/src/ui/ToolTip/SuggestionTooltip'
import tooltipIcon from '@lynit/shared/src/images/tool-tip.svg'
import { sharedContext } from '@lynit/shared/src/state'
import {
	setIsCatelogOpen,
	setNewConnId,
	setActiveConnections,
	setElementToDelete,
} from '@lynit/shared/src/state/actions'

import textIcon from '@lynit/shared/src/images/text-icon.svg'
import activeTextIcon from '@lynit/shared/src/images/active-text-icon.svg'
import beatsIcon from '@lynit/shared/src/images/beats-icon.svg'
import activeBeatsIcon from '@lynit/shared/src/images/active-beats-icon.svg'
import HtmlTooltip from '@lynit/shared/src/ui/ToolTip/HtmlTooltip'
import DeleteModal from '@lynit/shared/src/shared-ui/DeleteModal'
import Arrow from '../../../../shared-package/src/images/arrow.svg'
import { orderBy } from 'lodash'
import BeatsContainer from '@lynit/shared/src/ui/BeatsContainer'
// import arrow from '@lynit/shared/src/images/downCheveron.svg'

const useStyles = makeStyles(() => ({
	tooltip: {
		margin: 0,
		background: '#55534F',
		display: props => props.isTooltip && 'none',
	},
	tooltip1: {
		display: props => !props.isCollapsedBeat && 'none',
		background: '#55534F',
	},
	tooltipSm: {
		margin: '0 auto',
		background: 'transparent',
		width: '100%',
		padding: 0,
		marginTop: '6px !important',
	},
	initialsTooltip: {
		background: '#55534F',
	},
	arrow: {
		color: '#55534F',
	},
	timeline: {
		margin: '0',
		padding: '7px 5px',
	},
	timelineItem: {
		'&:before': {
			display: 'none',
		},
		minHeight: 'unset',
		boxSizing: 'border-box',
		gap: '0px',
		alignItems: 'center',
		justifyContent: 'center',
	},
	timelineContent: {
		padding: '0px 3px',
		width: 'calc(100% - 20px)',
	},
	timelineDot: {
		margin: 0,
		backgroundColor: '#78C091',
	},
	timelineChapter: {
		margin: 0,
		backgroundColor: nodeColors.Chapter,
		width: '8px',
		height: '8px',
		padding: '0',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '10px',
		color: '#000000',
	},
	hiddenTimelineConnector: {
		visibility: 'hidden',
	},
	timelineConnector: {
		background: '#78C091',
	},
	dashedTimelineConnector: {
		background: 'transparent',
		border: '1px dashed #78C091',
		width: 0,
	},
	tab: {
		color: '#888585',
		fontFamily: 'Source Sans Pro',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: 'normal',
		textTransform: 'none',
		minWidth: '70px',
		padding: '12px 5px',
		// alignItems: 'center',
		// justifyContent:"center",
	},
	tabSelected: {
		color: '#000000 !important',
	},
	indicator: {
		height: '6px',
		borderRadius: '10px',
		backgroundColor: '#FFC300',
	},
	tabs: {
		borderBottom: '6px solid #fff5d4',
		borderRadius: '4px',
	},
}))

const NewConnectionCard = ({
	id,
	//name,
	//type,
	referenceElement,
	destNode,
	isDefaultOpened,
	render,
	parentLocation="",
	handleClose = () => {},
}) => {
	const client = useApolloClient()
	const [performUpdateRelationshipMutation] = useUpdateRelationshipMutation()
	const [openedConnectionCard, setOpenedConnectionCard] = useState(false)

	const graphData = useContext(graphDataStateContext)
	const [newBeat, setNewBeat] = useState(null)
	//const [createBeat] = useCreateBeatMutation({ ignoreResults: true })
	const { createLog, toastHandler } = useContext(systemStateContext)
	const [isEdit, setEdit] = useState(false)
	//const [connectionDescription, setConnectionDescription] = useState(description)
	const [performDeleteConnectionMutation] = useDeleteConnectionMutation()
	const [reorderBeats] = useReorderBeatsMutation()
	const { data: relationships } = useGetRelationshipsQuery({ fetchPolicy: 'cache-only' , component:"NewConnectionCard"})
	const {
		state: {
			cacheConnData: { newConnId },
		},
		dispatch: sharedDispatch,
	} = useContext(sharedContext)
	const modalRef = useRef()
	const modalRef2 = useRef()

	const [isCollapsedBeat, setIsCollapsedBeat] = useState(true)
	const [isCollapsedDescription, setIsCollapsedDescription] = useState(true)
	const [lastOpenedRelationship, setLastOpenedRelationship] = useState(null)
	const [isMouseOverBeats, setIsMouseOverBeats] = useState(false)
	const [isMouseOverTextTab, setIsMouseOverTextTab] = useState(false)
	const [tabChangeDetected, setTabChangeDetected] = useState(null)

	// useEffect(() => {
	// 	const handleTabChange = () => {
	// 		setTabChangeDetected(true)
	// 	}

	// 	window.addEventListener('visibilitychange', handleTabChange)

	// 	return () => {
	// 		window.removeEventListener('visibilitychange', handleTabChange)
	// 	}
	// }, [])

	// useEffect(() => {
	// 	if ((isDefaultOpened || isNewStructureTag) && !tabChangeDetected) {
	// 		setIsCollapsedDescription(isDefaultOpened || isNewStructureTag)
	// 	} else {
	// 		setOpenedConnectionCard(false)
	// 	}
	// }, [isDefaultOpened, isNewStructureTag])


	const [beatsList,setBeatslist] = useState([])

	const relation = relationships?.relations?.find(rel => rel.id === id)

	useEffect(()=>{

		if(relation){
		const newBeatList = relation?.["sharedBeats"]?.map((beat,index)=> {return {...beat,order:(index)}})  
		const orderedBeatList= orderBy(newBeatList, ['order'], ['asc'])

		setBeatslist(orderedBeatList)
		}

		},[relation])

	

	useEffect(() => {
		if (newConnId === id || isDefaultOpened) {
			
			sharedDispatch(setNewConnId(false))
			setIsCollapsedDescription(false)
			
			createLog(
				'Connection Details Expanded',
				`{"method":"Create New Connection"}`,
				'Connection Card',
				null,
			)
		} 
	}, [])

	useEffect(() => {
		if (
			!isCollapsedBeat 
		) {
			const connectionCard = document.getElementById(`${id}-connectionCard`)

			//setTimeout(() => connectionCard?.scrollIntoView({ behavior: 'smooth', block: 'start' }), 200)
		}
	}, [isCollapsedBeat])

	useEffect(() => {

		if (newConnId !== id) {
			setOpenedConnectionCard(!(isCollapsedBeat && isCollapsedDescription))
		}
	}, [isCollapsedBeat,isCollapsedDescription])

	const defaultConnectionDescriptions = {
		Ally: 'Describe how these two characters help each other overcome their weaknesses.',
		Opponent: 'Describe how these two characters exploit each other’s weaknesses.',
		Protagonist: 'Describe how this Protagonist uniquely pursues this Arc?',
		Antagonist: 'Describe how this Antagonist uniquely complicates this Arc?',
		Supporting: 'Describe how these elements help each other overcome their weaknesses?',
		Opposing: 'Describe how these elements attack each other’s weaknesses?',
	}

	const classes = useStyles({
		isTooltip: false,
		isCollapsed: false,
	})

	destNode = graphData?.nodes[destNode?.id]
	referenceElement = graphData?.nodes[referenceElement?.id]


	const shortname = vizTitleLengthFormat(destNode?.name, 11)

	const handleStructureTagChange = (id, structureTag) => {
		let currentStructureTag
		if (structureTag === 'Ally' || structureTag === 'Opponent') {
			currentStructureTag = structureTag === 'Ally' ? 'Opponent' : 'Ally'
		} else if (structureTag === 'Protagonist' || structureTag === 'Antagonist') {
			currentStructureTag = structureTag === 'Protagonist' ? 'Antagonist' : 'Protagonist'
		} else if (structureTag === 'Opposing' || structureTag === 'Supporting') {
			currentStructureTag = structureTag === 'Opposing' ? 'Supporting' : 'Opposing'
		} else if (!structureTag) {
			currentStructureTag = structureTagMatcher(
				referenceElement?.__typename,
				destNode.__typename,
				true,
			)
		} else {
			currentStructureTag = structureTag
		}

		// updateCacheField(client,{ id, __typename: 'Relationship' },{structureTag:currentStructureTag},true)

		//updateCacheStructureTagElement(client, { id, __typename: 'Relationship' }, currentStructureTag, true)
		performUpdateRelationshipMutation({
			id,
			input: {
				structureTag: currentStructureTag,
			},
			client,
			__typename: 'Relationship',
			newMapValue: { structureTag: currentStructureTag, updatedAt: new Date().toISOString() },
			broadcast: true,
		})
	}


	


	
	

	return (
		<ConnectionContainer id={`${id}-connectionCard`}>
			<Div beatsDriven={relation?.beatsDriven}>
				<HtmlTooltip
					title={
						<img
							src={deleteIcon}
							onClick={() => {
								if (!JSON.stringify(id)?.includes('temp')) {
									sharedDispatch(
										setElementToDelete({
											isActive: true,
											type:'Relationship',
											id,
										}),
									)
									createLog(
										'Connection Deletion Attempted',
										`{"workflowStep":${1},"connectionType":"Static"}`,
										'StoryViz',
										'Connection Deletion',
									)
								}
							}}
						/>
					}
					placement="left"
					// onOpen={() => setIsMouseOverRelationship(true)}
				>
					<ElementCardContainer
					// onMouseEnter={() => setIsMouseOverRelationship(true)}
					// onMouseLeave={() => setIsMouseOverRelationship(false)}
					>
						<ConnectionCardContainer
							onMouseEnter={() =>
								createLog(
									'Relationship Hover',
									`{"driverType":"${referenceElement?.__typename}", "relationshipGroupDriverType": "${destNode.__typename}", "structureTag": "${relation?.structureTag}"}`,
									'Connection Card',
									null,
								)
							}
							onClick={e => {
								e.stopPropagation()
								if (lastOpenedRelationship === 'Beats') {
									setIsCollapsedBeat()
									setIsCollapsedDescription(false)
							
								} else {

									setIsCollapsedDescription(prev => !prev)
									setLastOpenedRelationship('Description')
								}
								setTabChangeDetected(true)
								createLog(
									`Connection Details ${!openedConnectionCard ? 'Expanded' : 'Collapsed'}`,
									`{"method":"Clicked on driver name", "beatsDriven":"${relation?.beatsDriven}"}`,
									'Connection Card',
									null,
								)

								createLog(
									'Relationship Clicked',
									`{"driverType":"${referenceElement?.__typename}", "relationshipGroupDriverType": "${destNode.__typename}", "structureTag": "${relation?.structureTag}"}`,
									'Connection Card',
									null,
								)
							}}
						>
							<ElementName onClick={e => {}}>
								<IconContainer isSelected={true} color={referenceElement?.__typename}>
									<img src={getNodeIcon(referenceElement?.__typename)} />
								</IconContainer>{' '}
								<HtmlTooltip
									title={
										<TooltipWrapper>
											<TooltipDescription>Open {referenceElement?.name}</TooltipDescription>
										</TooltipWrapper>
									}
									placement={'bottom'}
									classes={{
										tooltip: classes.tooltipSm,
									}}
									ref={modalRef2}
								>
									<ElementNameLable
										onClick={e => {
											e.stopPropagation()
											sharedDispatch(setActiveConnections({ 
												isActive: true, 
												elementId: referenceElement.id, 
												elementType: referenceElement.__typename,
												docType:"Driver", 
												appView: graphData?.nodes?.[referenceElement.id]?.coreElement === "true" ? "Premise": "Outlining" }))
										}}
									>
										{vizTitleLengthFormat(referenceElement?.name, 11)}
									</ElementNameLable>
								</HtmlTooltip>
							</ElementName>
							<NewConnectionWrapper
								isOpened={openedConnectionCard}
								supporting={
									relation?.structureTag === 'Ally' ||
									relation?.structureTag === 'Supporting' ||
									relation?.structureTag === 'Protagonist'
										? true
										: relation?.structureTag === 'Opponent' ||
										relation?.structureTag === 'Opposing' ||
										relation?.structureTag === 'Antagonist'
										? false
										: true
								}
							>
								<ConnectionImage
									supporting={
										relation?.structureTag === 'Ally' ||
										relation?.structureTag === 'Supporting' ||
										relation?.structureTag === 'Protagonist'
											? true
											: relation?.structureTag === 'Opponent' ||
											relation?.structureTag === 'Opposing' ||
											relation?.structureTag === 'Antagonist'
											? false
											: true
									}
									dashed={!beatsList?.length}
									isCollapsed={!openedConnectionCard}
								>
									<div>
										{openedConnectionCard ? (
											<StructureTagLable
												supporting={
													relation?.structureTag === 'Ally' ||
													relation?.structureTag === 'Supporting' ||
													relation?.structureTag === 'Protagonist'
														? true
														: relation?.structureTag === 'Opponent' ||
														relation?.structureTag === 'Opposing' ||
														relation?.structureTag === 'Antagonist'
														? false
														: true
												}
												onClick={e => {
													e.stopPropagation()
													handleStructureTagChange(id, relation?.structureTag)
												}}
											>
												{relation?.structureTag}
											</StructureTagLable>
										) : (
											<img
												src={
													relation?.structureTag === 'Ally' ||
													relation?.structureTag === 'Supporting' ||
													relation?.structureTag === 'Protagonist'
														? supportingConnectionIcon
														: relation?.structureTag === 'Opponent' ||
														relation?.structureTag === 'Opposing' ||
														relation?.structureTag === 'Antagonist'
														? opposingConnectionIcon
														: supportingConnectionIcon
												}
												onClick={e => {
													e.stopPropagation()
													handleStructureTagChange(id, relation?.structureTag)
												}}
												width={'22px'}
												height={'13px'}
											/>
										)}
									</div>
								</ConnectionImage>
								<Connection>
									<BeatsTextWrapper
										bordered
										onClick={e => {
											e.stopPropagation()
										if(!openedConnectionCard){
											createLog(
												`Connection Details ${!openedConnectionCard ? 'Expanded' : 'Collapsed'}`,
												`{"method":"Clicked on Description Icon"}`,
												'Connection Card',
												null,
											)
										}
											
											createLog(
												'Relationship Clicked',
												`{"driverType":"${referenceElement?.__typename}", "relationshipGroupDriverType": "${destNode.__typename}", "structureTag": "${relation?.structureTag}"}`,
												'Connection Card',
												null,
											)

											setIsCollapsedDescription(prev => !prev)
											setIsCollapsedBeat(true)
											setLastOpenedRelationship('Description')
											setTabChangeDetected(true)
										}}
										onMouseEnter={() => setIsMouseOverTextTab(true)}
										onMouseLeave={() => setIsMouseOverTextTab(false)}
									>
										<img
											src={!isCollapsedDescription || isMouseOverTextTab ? activeTextIcon : textIcon}
											width={'25px'}
											height={'25px'}
										/>
									</BeatsTextWrapper>
									<BeatsTextWrapper
										onClick={e => {
											e.stopPropagation()
											
											if (openedConnectionCard) {
												createLog(
													`Connection Details ${!openedConnectionCard ? 'Expanded' : 'Collapsed'}`,
													`{"method":"Clicked on Beats Icon", "beatsDriven":"${relation?.beatsDriven}"}`,
													'Connection Card',
													null,
												)
											}
											setIsCollapsedDescription(true)
											setIsCollapsedBeat(prev => !prev)
											setLastOpenedRelationship('Beats')
											setTabChangeDetected(true)

											createLog(
												'Relationship Clicked',
												`{"driverType":"${referenceElement?.__typename}", "relationshipGroupDriverType": "${destNode.__typename}", "structureTag": "${relation?.structureTag}"}`,
												'Connection Card',
												null,
											)
										}}
										onMouseEnter={() => setIsMouseOverBeats(true)}
										onMouseLeave={() => setIsMouseOverBeats(false)}
									>
										<BeatsCountLabel isActive={!isCollapsedBeat || isMouseOverBeats}>
											{beatsList?.length}
										</BeatsCountLabel>{' '}
										<img
											src={
												isCollapsedBeat
													? isMouseOverBeats
														? activeBeatsIcon
														: beatsIcon
													: activeBeatsIcon
											}
											width={'25px'}
											height={'25px'}
										/>
									</BeatsTextWrapper>
								</Connection>
							</NewConnectionWrapper>

							<ElementName onClick={() => {}}>
								<IconContainer isSelected={true} color={destNode.__typename}>
									<img src={getNodeIcon(destNode.__typename)} />
								</IconContainer>{' '}
								<HtmlTooltip
									title={
										<TooltipWrapper>
											<TooltipDescription>Open {destNode?.name}</TooltipDescription>
										</TooltipWrapper>
									}
									placement={'bottom'}
									classes={{
										tooltip: classes.tooltipSm,
									}}
									ref={modalRef}
								>
									<ElementNameLable
										onClick={e => {
											e.stopPropagation()
											
											sharedDispatch(setActiveConnections({ 
												isActive: true, 
												elementId: destNode.id, 
												elementType: destNode.__typename,
												docType:"Driver", 
												appView: graphData?.nodes?.[destNode.id]?.coreElement === "true" ? "Premise": "Outlining" }))
										}}
									>
										{shortname}
									</ElementNameLable>
								</HtmlTooltip>
							</ElementName>
						</ConnectionCardContainer>
					</ElementCardContainer>
				</HtmlTooltip>

				<ConnectionDescription isVisible={!isCollapsedDescription}>
					<DescriptionField
						id={id}
						key={id}
						fieldName={'description'}
						type={destNode.__typename}
						isEdit={isEdit}
						setEdit={setEdit}
						connectionType="Static"
						createLog={createLog}
						placeholder={defaultConnectionDescriptions[relation?.structureTag]}
						render={render}
					/>
				</ConnectionDescription>
			</Div>
			{!isCollapsedBeat &&
				(<BeatsContainer
				referenceElement={relation}
				location={parentLocation + " Connection Card"}
				locationId={id}
				beatsList={beatsList}
				connectionTypeField={"sharedBeats"}
				/>)
			}
			
		</ConnectionContainer>
	)
}



export default React.memo(NewConnectionCard)


const Div = styled.div`
	margin-bottom: -7px;
	border-radius: 4px;
	position: relative;
	//display: flex;
	//width: 75%;
	//align-items: start;
	//justify-content: space-between;
	//gap: 20px;
	cursor: pointer;
	//padding: 3px 5px;
	//left: 40px;
`

const ConnectionContainer = styled.div`
	border-radius: 4px;
	//background-color: #fff;
	position: relative;
	//display: flex;
	// width: 85%;
	//align-items: start;
	//justify-content: space-between;
	//gap: 20px;
	//cursor: pointer;
	// padding: 3px 5px;
	// left: 32px;
	// top: -3px;
`



