/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext, useRef } from 'react'

import { useHistory } from 'react-router-dom'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import { userStateContext } from '@lynit/shared/src/state/userProvider'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'

import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import {

	useGetRelationshipsQuery,
	useLazyGetRelationshipsQuery,
} from '@lynit/shared/src/hooks'

import { useMemo } from 'react'
import { useCallback } from 'react'
import ThreePartDoc from '../ThreePartDoc'

import StoryStructure from '../StoryStructure/StoryStructure'
import DriverList from '@lynit/shared/src/ui/DriverList'
import getConnections from '@lynit/shared/src/utils/getConnections'
import { DriverContainer, DriverLabel, PremiseDriver, PremiseRelation, PremiseWrapper } from './style'
import AllRelationship from '@lynit/shared/src/ui/Search/AllRelationshipList'
import { analyticsDataStateContext } from '@lynit/shared/src/state/analyticsDataProvider'
import { orderBy } from 'lodash'
import { Tooltip } from '@mui/material'
import { ExtraBeatsContainer } from '../ThreePartDoc/styles'
import missingPremiseDriverIcon from '@lynit/shared/src/images/missing-premise-driver.svg'
import { getNodeIcon } from '@lynit/shared/src/utils/utils'
import { EmptyDriverDescription, EmptyDriverWrapper } from '../ListElement/styles'
import { BeatDescription } from '../DriverDoc/styles'


const PremisePlanner = ({}) => {
	const graphData = useContext(graphDataStateContext)
	
	const { createLog, isMobile } = useContext(systemStateContext)
	const { data: relationship, loading: loadingRelationship } = useGetRelationshipsQuery({fetchPolicy: 'cache-only', component:"PremisePlanner"})
	const {premiseDriverList, missingPremiseRelationships} = useContext(analyticsDataStateContext)
	const [premiseRelationshipList, setpremiseRelationshipList] = useState([])

	const premiseID = graphData.nodesByType["Premise"]?.[0]?.id

	const BottomSectionInputComponent = useCallback(() => {
		return <StoryStructure />
	}, [premiseID])

	
	

	useEffect(() => {
		if (relationship?.relations) {
			let connectionArray = relationship.relations
				.filter(rel => rel.connectionType === 'Static')
				.map((result, index) => {
					return getConnections(result?.sourceNode?.id, [], graphData?.nodes)?.all.filter(
						connection => connection?.destNode.id === result?.destNode?.id,
					)
				})
				.filter(Boolean)
				.flat()
			let premiseRelation = connectionArray.filter(
				connection =>
					graphData?.nodes[connection.destNode.id].coreElement === 'true' &&
					graphData?.nodes[connection.sourceNode.id].coreElement === 'true',
			)

			premiseRelation = orderBy(premiseRelation, ['createdAt'],['desc'])

			setpremiseRelationshipList(premiseRelation)
		}
		
	}, [relationship?.relations, graphData?.nodes])


	const middleSectionDecriptionFields = {
		Premise: [
			{
				label: 'Summary',
				aiRecommendations: false,
				coreElement: 'true',
				slug: 'description',
				placeholder: `What is the story about? Summarize it in 2-3 sentences. Include the protagonist, the antagonist, the plot, and a hint of the theme.`,
			},
			{
				label: 'Narrative Drive',
				aiRecommendations: false,
				coreElement: 'true',
				slug: 'narrativeDrive',
				placeholder: `What drives the story? Why will the reader keep turning the page?`,
			},
		],
	}

	const MiddleSectionInputComponent = useCallback(() => {
		return (
			<PremiseWrapper>
				<DriverContainer>
					<DriverLabel>
						Premise Drivers
						{ (<Tooltip
								title={'Double check if too many Drivers are included in your premise. Less than 6 is ideal.'}
								placement="top"
								arrow
								classes={{
									tooltip: {
										background: '#55534F',
									},
									arrow: {
										color: '#55534F',
									},
								}}
							>
								<ExtraBeatsContainer 
								style={{visibility:  premiseDriverList.length > 5 ? 'visible' : 'hidden', height: '16px'}} 
								isMulti>
									<p style={{color:"red"}}>
										{premiseDriverList.length||0}
									</p>
								</ExtraBeatsContainer>
							</Tooltip>)}
					</DriverLabel>
					<PremiseDriver>
						{premiseDriverList.length > 0 ? premiseDriverList.map((element, index) => {
							return (
								<DriverList
									key={element?.id}
									id={element?.id}
									name={element?.name}
									type={element?.__typename}
									description={element?.description}
									relationshipsCount={element?.staticConnections?.length}
									isDefaultOpened={element.source}
									render={'driver'}
								/>
							)
						}) : (
							<EmptyDriverWrapper extraPadding>
								<EmptyDriverDescription>{`Create Premise Drivers from the Navbar "Create a Story Element" button or in the visualization, drag an existing driver into the Premise.`}</EmptyDriverDescription>
							</EmptyDriverWrapper>
						)}
					</PremiseDriver>
				</DriverContainer>
				<DriverContainer>
					<DriverLabel>
						Premise Relationships
						{ (<Tooltip
								title={'Missing Relationships Between Premise Drivers.'}
								placement="top"
								arrow
								classes={{
									tooltip: {
										background: '#55534F',
									},
									arrow: {
										color: '#55534F',
									},
								}}
							>
								<ExtraBeatsContainer
									style={{visibility: missingPremiseRelationships.length >0 ? 'visible' : 'hidden'}} 
									isMulti>
									<p>
										{missingPremiseRelationships.length}
									</p>
									<img src={missingPremiseDriverIcon} />
								</ExtraBeatsContainer>
							</Tooltip>)}
					</DriverLabel>
					<PremiseRelation>
						{(premiseRelationshipList.length + missingPremiseRelationships.length) > 0 ? (<AllRelationship premiseRelationshipList={premiseRelationshipList} />)
						: (
							<EmptyDriverWrapper extraPadding>
								<EmptyDriverDescription>{`Create Premise Drivers and we will recommend Relationships between them.`}</EmptyDriverDescription>
							</EmptyDriverWrapper>
						)}
					</PremiseRelation>
					
				</DriverContainer>
			</PremiseWrapper>
		)
	}, [premiseDriverList.length,premiseRelationshipList.filter(rel => rel.structureTag).length])

	// return useMemo(() => {
	return premiseID ? (
		<ThreePartDoc
		id={premiseID}
		docType={"Premise"}
		middleSectionDecriptionFields={middleSectionDecriptionFields["Premise"]}
		MiddleSectionInputComponent={MiddleSectionInputComponent}
		BottomSectionInputComponent={BottomSectionInputComponent}
		docInstructionsText={"The Premise is the core of your story. It includes the Drivers (Characters, Arcs, Themes, Settings, or Events) that you would mention in a two sentence summary of the story. Flush out your Premise Drivers, Premise Relationships, and your Story Structure."}
		/>
	) : (
		<></>
	)
	
}

export default React.memo(PremisePlanner)
