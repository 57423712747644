/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext, useRef } from 'react'

import { useHistory } from 'react-router-dom'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import { userStateContext } from '@lynit/shared/src/state/userProvider'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'
import {
	beatsDataDispatchContext,
	beatsDataStateContext,
} from '@lynit/shared/src/state/beatsProvider'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import {

	setActiveConnections,
	setCurrentStory,
	setIsCatelogOpen,
} from '@lynit/shared/src/state/actions'
import ToolTip from '@lynit/shared/src/ui/ToolTip'
import { autoSaveStateContext } from '@lynit/shared/src/state/autoSaveProvider'
import { toastHandler } from '@lynit/shared/src/utils/backendHandler'
import {
	createCacheElement,
	deleteCacheElement,
	updateCacheField,
	updateCacheNameElement,
} from '@lynit/shared/src/utils/apollo'
import { getNodeIcon, createNodeId, nodeTypeForId } from '@lynit/shared/src/utils/utils'
import {
	useReorderNodesMutation,
	useCreateNodeMutation,
	useEditStoryMutation,
	useGetRelationshipsQuery,
	useUpdateNodeMutation,
} from '@lynit/shared/src/hooks'

import { useMemo } from 'react'
import { useCallback } from 'react'
import ThreePartDoc from '../ThreePartDoc'
import ChapterView from '@lynit/story-outline/src/ui/ChapterView'

const WritingPlanner = ({
}) => {
	const graphData = useContext(graphDataStateContext)

	
		
	
	const { createLog, isMobile } = useContext(systemStateContext)

	
	const writingID = graphData?.allNodes?.nodes?.find(node=> node?.id.startsWith("wrt"))?.id


	
	const BottomSectionInputComponent = useCallback(() => {
		return(<ChapterView
			createLog={createLog}
			isVisualizationExpanded={false}
			isExpandingView={true}
			storyOutlineLoaded={true}
		/>)
	},[])
	
	const middleSectionDecriptionFields = {
		Writing: [
			{ label: 'Tone and Style', slug: 'toneAndStyle', placeholder: `Describe the tone and style. Think about how they support the overall story structure.`, },
			{ label: 'Narrative Voice', slug: 'narrativeVoice', placeholder: `Who is telling this story and how does that heighten the experience?`, },
		],
		
	}

	const MiddleSectionInputComponent = useCallback(() => {

		return(
			<></>
		)


	},[])


	// return useMemo(() => {
	return writingID ? (
		<ThreePartDoc
		id={writingID}
		docType={"Writing"}
		middleSectionDecriptionFields={middleSectionDecriptionFields["Writing"]}
		MiddleSectionInputComponent={MiddleSectionInputComponent}
		BottomSectionInputComponent={BottomSectionInputComponent}
		docInstructionsText={"Plan and write your Chapters here."}
		/>
	) : (
		<></>
	)
	
}

export default React.memo(WritingPlanner)
