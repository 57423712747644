import { systemStateContext } from '../../state/systemProvider'

import React, { useContext, useEffect, useState } from 'react'
import { vizTitleLengthFormat } from '../../utils/utils'
import activeTextIcon from '@lynit/shared/src/images/active-text-icon.svg'
import beatsIcon from '@lynit/shared/src/images/beats-icon.svg'
import activeBeatsIcon from '@lynit/shared/src/images/active-beats-icon.svg'
import textIcon from '@lynit/shared/src/images/text-icon.svg'
import relationshipIcon from '@lynit/shared/src/images/relationship-icon.svg'
import activeRelationshipIcon from '@lynit/shared/src/images/active-relationship-icon.svg'
import {
	BeatsCountLabel,
	BeatsTextWrapper,
	Connection,
	ConnectionDescription,
	ConnectionElement,
	DriverContainer,
	DriverLabel,
	DriverWrapper,
	ElementNameLable,
	IconContainer,
	ExistingBeatLabel,
	ExistingBeat,
	CreateBeatWrapper,
} from './styles'
import DescriptionField from '@lynit/shared/src/ui/DescriptionField'
import getConnections from '../../utils/getConnections'
import { graphDataStateContext } from '../../state/graphDataProvider'
import NewConnectionCard from '@lynit/layout/src/ui/ListElement/NewConnectionCard'
import {
	EmptyDriverDescription,
	EmptyDriverWrapper,
	NewIndividualConnectionWrapper,
} from '@lynit/layout/src/ui/ListElement/styles'
import { makeStyles } from '@mui/styles'

import { beatsDataDispatchContext, beatsDataStateContext } from '../../state/beatsProvider'
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable'
import {
	elementReordered,
	setActiveConnections,
	setElementToDelete,
	setIsCatelogOpen,
} from '../../state/actions'
import useQuillAutoSave from '../AutoSave/useQuillAutoSave'
import { nodeColors } from '@lynit/shared/src/utils/commonStyles'
import orderBy from 'lodash/orderBy'
import { createNodeId, getNodeIcon, nodeTypeForId } from '@lynit/shared/src/utils/utils'
import {
	createCacheConnection,
	createCacheElement,
	deleteCacheConnections,
	deleteCacheElement,
} from '@lynit/shared/src/utils/apollo'
import {
	useGetRelationshipsQuery,
	useReorderNodesMutation,
	useUpdateNodeMutation,
	useDeleteConnectionMutation,
	useCreateBeatMutation,
} from '@lynit/shared/src/hooks'
import { useApolloClient } from '@apollo/client'
import { userStateContext } from '../../state/userProvider'
import DeleteModal from '../../shared-ui/DeleteModal'
import { sharedContext } from '../../state'
import HtmlTooltip from '../ToolTip/HtmlTooltip'
import deleteIcon from '@lynit/shared/src/images/deleteIcon.svg'
import Arrow from '../../../../shared-package/src/images/arrow.svg'

import {
	Tooltip,
	TooltipDescription,
	TooltipLabel,
	TooltipWrapper,
} from '@lynit/layout/src/ui/DriverDoc/styles'
import { useRef } from 'react'
import MissingStructureTagDriver from '@lynit/layout/src/ui/ListElement/MissingStructureTagDriver'
import CreateConnectionModal from '@lynit/shared/src/ui/CreateConnectionModal'
import { useDndContext, useDroppable } from '@dnd-kit/core'
import BeatsContainer from '../BeatsContainer/BeatsContainer'

const DriverList = ({ type, id, isDefaultOpened }) => {
	const modalRef = useRef()
	const client = useApolloClient()
	const [lastOpenedRelationship, setLastOpenedRelationship] = useState(null)
	const [isCollapsed, setIsCollapsed] = useState(false)
	const [isCollapsedDescription, setIsCollapsedDescription] = useState(false)
	const [isMouseOverBeats, setIsMouseOverBeats] = useState(false)
	const [isMouseOverTextTab, setIsMouseOverTextTab] = useState(false)
	const [isMouseOverRelashionshipTab, setIsMouseOverRelashionshipTab] = useState(false)
	const [openedTab, setOpendedTab] = useState(0)
	const { createLog, toastHandler } = useContext(systemStateContext)
	const [connectionsArr, setConnectionsArr] = useState([])
	const { data: relationships } = useGetRelationshipsQuery({fetchPolicy: 'cache-only', component:"DriverList"})
	const graphData = useContext(graphDataStateContext)
	const { reorderedPosition } = useContext(beatsDataStateContext)
	const beatsDataDispatch = useContext(beatsDataDispatchContext)
	const [beatContentChanged, setBeatContentChanged] = useState(false)
	const [currentElement, setCurrentElement] = useState()
	const [newBeat, setNewBeat] = useState(null)
	const [createBeat] = useCreateBeatMutation({ ignoreResults: true })
	const [tempBeatContent, setTempBeatContent] = useState('')
	const [performDeleteConnectionMutation] = useDeleteConnectionMutation()
	const { user, refetchUser } = useContext(userStateContext)
	const [reorderNodes] = useReorderNodesMutation()
	const [tabChangeDetected, setTabChangeDetected] = useState(null)
	const [lastOpenedTab, setLastOpenedTab] = useState()
	const [mouseOverDriverName, setMouseOverDriverName] = useState(false)
	const [connectionModal, setConnectionModal] = useState(false)
	const [connectionModalType, setConnectionModalType] = useState(false)
	const [openedConnectionCard, setOpenedConnectionCard] = useState(false)
	const [currentElementConnection, setCurrentElementConnection] = useState(false)
	const [beatsList, setBeatslist] = useState([])

	const {
		state: { deleteNode, activeConnections },
		dispatch: sharedDispatch,
	} = useContext(sharedContext)

	const [updateBeat] = useUpdateNodeMutation('Beat')

	// useEffect(async () => {
	// 	if (newBeat && tempBeatContent) {
	// 		client.cache.modify({
	// 			id: client.cache.identify({
	// 				id: newBeat.id,
	// 				__typename: newBeat.__typename,
	// 			}),
	// 			fields: {
	// 				description() {
	// 					return tempBeatContent.description
	// 				},
	// 			},
	// 		})
	// 		try {
	// 			await updateBeat({
	// 				variables: {
	// 					id: newBeat.id,
	// 					input: {
	// 						description: tempBeatContent.description,
	// 						updatedAt: new Date().toISOString(),
	// 					},
	// 				},
	// 			})
	// 		} catch (error) {
	// 			console.error(error)
	// 			client.cache.modify({
	// 				id: client.cache.identify({
	// 					id: newBeat.id,
	// 					__typename: newBeat.__typename,
	// 				}),
	// 				fields: {
	// 					description() {
	// 						return ''
	// 					},
	// 				},
	// 			})
	// 		}
	// 		setNewBeat(null)
	// 		setTempBeatContent('')
	// 	}
	// }, [tempBeatContent, newBeat])

	const name = graphData?.nodes?.[id]?.name
	const description = graphData?.nodes?.[id]?.description
	useEffect(() => {
		const newBeatList = graphData?.nodes?.[id]?.driverConnections?.map(connection => {
			const beatId = connection?.sourceNode?.id?.startsWith('bea')
				? connection?.sourceNode?.id
				: connection?.destNode?.id
			return { id: beatId, connectionId: connection.id, order: +connection.order }
		})

		const orderedBeatList = orderBy(newBeatList, ['order'], ['asc'])

		setBeatslist(orderedBeatList)
	}, [graphData?.nodes?.[id]?.driverConnections])

	const useStyles = makeStyles(() => ({
		tooltip: {
			margin: 0,
			background: '#55534F',
			display: props => props.isTooltip && 'none',
		},
		tooltip1: {
			display: props => !props.isCollapsed && 'none',
			background: '#55534F',
		},
		initialsTooltip: {
			background: '#55534F',
		},
		tooltipSm: {
			margin: '0 auto',
			background: 'transparent',
			width: '100%',
			padding: 0,
			marginTop: '6px !important',
			// margin: '6px 0',
		},
		arrow: {
			color: '#55534F',
		},
		timeline: {
			margin: '0',
			padding: '7px 5px',
		},
		timelineItem: {
			'&:before': {
				display: 'none',
			},
			minHeight: 'unset',
			boxSizing: 'border-box',
			justifyContent: 'center',
			alignItems: 'center',
		},
		timelineContent: {
			padding: '0px 3px',
			width: 'calc(100% - 20px)',
		},
		timelineDot: {
			margin: 0,
			backgroundColor: '#78C091',
		},
		timelineChapter: {
			margin: 0,
			backgroundColor: nodeColors.Chapter,
			width: '8px',
			height: '8px',
			padding: '0',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			fontSize: '10px',
			color: '#000000',
		},
		hiddenTimelineConnector: {
			visibility: 'hidden',
		},
		timelineConnector: {
			background: '#78C091',
		},
		dashedTimelineConnector: {
			background: 'transparent',
			border: '1px dashed #78C091',
			width: 0,
		},
		tab: {
			color: '#888585',
			fontFamily: 'Source Sans Pro',
			fontSize: '14px',
			fontStyle: 'normal',
			fontWeight: 700,
			lineHeight: 'normal',
			textTransform: 'none',
			minWidth: '70px',
			padding: '2px 5px',
		},
		tabSelected: {
			color: '#000000 !important',
		},
		indicator: {
			height: '6px',
			borderRadius: '10px',
			backgroundColor: '#FFC300',
		},
		tabs: {
			borderBottom: '6px solid #fff5d4',
			borderRadius: '4px',
		},
	}))

	const classes = useStyles({
		isTooltip: false,
		isCollapsed: false,
	})

	const defaultConnectionDescriptions = {
		Ally: 'Describe how these two characters help each other overcome their weaknesses.',
		Opponent: 'Describe how these two characters exploit each other’s weaknesses.',
		Protagonist: 'Describe how this Protagonist uniquely pursues this Arc?',
		Antagonist: 'Describe how this Antagonist uniquely complicates this Arc?',
		Supporting: 'Describe how these elements help each other overcome their weaknesses?',
		Opposing: 'Describe how these elements attack each other’s weaknesses?',
	}

	// useEffect(() => {
	// 	if (
	// 		reorderedPosition?.referenceElement?.id === id &&
	// 		reorderedPosition?.source?.index !== reorderedPosition?.destination?.index
	// 	) {
	// 		setDriverConnections(
	// 			arrayMove(
	// 				beatsList,
	// 				reorderedPosition.source.index,
	// 				reorderedPosition.destination.index,
	// 			),
	// 		)
	// 		beatsDataDispatch(elementReordered())
	// 	}
	// }, [reorderedPosition])

	useEffect(() => {
		if (id) {
			setConnectionsArr(getConnections(id, relationships?.relations, graphData?.nodes))
		}
	}, [relationships?.relations, graphData.nodes])

	// useEffect(() => {
	// 	const handleTabChange = () => {
	// 		setTabChangeDetected(true)
	// 	}

	// 	window.addEventListener('visibilitychange', handleTabChange)

	// 	return () => {
	// 		window.removeEventListener('visibilitychange', handleTabChange)
	// 	}
	// }, [])

	useEffect(() => {
		if (!tabChangeDetected) {
			setOpendedTab(isDefaultOpened ? 1 : 0)
			//setIsCollapsedDescription(isDefaultOpened)
			setLastOpenedRelationship('Description')
		}
	}, [isDefaultOpened, tabChangeDetected])

	return (
		<DriverContainer>
			<HtmlTooltip
				title={
					<img
						src={deleteIcon}
						onClick={() => {
							if (!JSON.stringify(id)?.includes('temp')) {
								sharedDispatch(
									setElementToDelete({
										isActive: true,
										type,
										id,
										nodeName: name,
									}),
								)
								createLog(
									'Beat Deletion Attempted',
									`{"workflowStep":${1},"BeatType":"Static"}`,
									'StoryViz',
									'Beat Deletion',
								)
							}
						}}
					/>
				}
				placement="left"
			>
				<DriverWrapper>
					<DriverLabel
						onClick={() => {
							if (openedTab) {
								setOpendedTab(0)
							} else {
								setOpendedTab(lastOpenedTab ? lastOpenedTab : 1)
							}
						}}
					>
						<IconContainer isSelected={true} color={type}>
							<img src={getNodeIcon(type)} />
						</IconContainer>{' '}
						<HtmlTooltip
							title={
								<TooltipWrapper>
									<TooltipDescription>Open {name}</TooltipDescription>
								</TooltipWrapper>
							}
							placement={'bottom'}
							classes={{
								tooltip: classes.tooltipSm,
							}}
							ref={modalRef}
						>
							<ElementNameLable
								className="driverCardName"
								onClick={e => {
									e.stopPropagation()

									sharedDispatch(
										setActiveConnections({
											isActive: true,
											elementId: id,
											elementType: type,
											docType: 'Driver',
											appView:
												graphData?.nodes?.[id]?.coreElement === 'true' ? 'Premise' : 'Outlining',
										}),
									)
								}}
								onMouseEnter={() => {
									setMouseOverDriverName(true)
								}}
								onMouseLeave={() => {
									setMouseOverDriverName(false)
								}}
							>
								<div>{vizTitleLengthFormat(name, 25)}</div>
							</ElementNameLable>
						</HtmlTooltip>
					</DriverLabel>
					<Connection isTransparent={openedTab}>
						<BeatsTextWrapper
							bordered
							onClick={() => {
								if (openedTab === 1) {
									setOpendedTab(0)
									setLastOpenedTab(0)
								} else {
									setOpendedTab(1)
									setLastOpenedTab(1)
								}
								setTabChangeDetected(true)
								setIsCollapsedDescription(prev => !prev)
								setLastOpenedRelationship('Description')
							}}
							onMouseEnter={() => setIsMouseOverTextTab(true)}
							onMouseLeave={() => setIsMouseOverTextTab(false)}
						>
							<img
								src={openedTab === 1 || isMouseOverTextTab ? activeTextIcon : textIcon}
								width={'25px'}
								height={'25px'}
							/>
						</BeatsTextWrapper>
						<BeatsTextWrapper
							bordered
							onClick={e => {
								if (openedTab === 2) {
									setOpendedTab(0)
									setLastOpenedTab(0)
								} else {
									setOpendedTab(2)
									setLastOpenedTab(2)
								}
								setTabChangeDetected(true)
								setIsCollapsedDescription(false)
								setLastOpenedRelationship('Beats')
							}}
							onMouseEnter={() => setIsMouseOverRelashionshipTab(true)}
							onMouseLeave={() => setIsMouseOverRelashionshipTab(false)}
						>
							<BeatsCountLabel isActive={openedTab === 2 || isMouseOverRelashionshipTab}>
								{connectionsArr?.all?.length}
							</BeatsCountLabel>{' '}
							<img
								src={
									openedTab === 2 || isMouseOverRelashionshipTab
										? activeRelationshipIcon
										: relationshipIcon
								}
								width={'25px'}
								height={'25px'}
							/>
						</BeatsTextWrapper>
						<BeatsTextWrapper
							onClick={e => {
								setTabChangeDetected(true)
								if (openedTab === 3) {
									setOpendedTab(0)
									setLastOpenedTab(0)
								} else {
									setOpendedTab(3)
									setLastOpenedTab(3)
								}
							}}
							onMouseEnter={() => setIsMouseOverBeats(true)}
							onMouseLeave={() => setIsMouseOverBeats(false)}
						>
							<BeatsCountLabel isActive={openedTab === 3 || isMouseOverBeats}>
								{beatsList?.length}
							</BeatsCountLabel>{' '}
							<img
								src={openedTab === 3 || isMouseOverBeats ? activeBeatsIcon : beatsIcon}
								width={'25px'}
								height={'25px'}
							/>
						</BeatsTextWrapper>
					</Connection>
				</DriverWrapper>
			</HtmlTooltip>{' '}
			{/* bottom section */}
			{openedTab === 1 && (
				<ConnectionDescription isVisible={openedTab === 1}>
					<DescriptionField
						id={id}
						key={id}
						fieldName={'description'}
						type={type}
						elementDescription={description ?? ''}
						connectionType="Static"
						createLog={createLog}
						placeholder={`Summarize your ${type} here`}
					/>
				</ConnectionDescription>
			)}
			{openedTab === 2 && (
				<ConnectionDescription className="unique" isVisible>
					{connectionsArr?.all.length > 0 ? (
						<ConnectionElement>
							{connectionsArr?.all
								?.filter(
									node =>
										!node.structureTag &&
										node?.destNode?.__typename !== 'Premise' &&
										node?.sourceNode?.__typename !== 'Premise',
								)
								.map((node, index) => (
									<NewIndividualConnectionWrapper
										key={`outer-${node.id}`}
										// firstCard={missingStructureTagConnection?.length === 0 && index === 0}
										// prevConnCard={missingStructureTagConnection?.length > 0}
										type={node.__typename}
										isMissingDriver={true}
										beatsDriven={0}
										isCollapsed={true}
									>
										<MissingStructureTagDriver
											key={`structureTag-${node.id}`}
											id={node.id}
											type={node.__typename}
											text={node.name}
											handleClick={(event, connectionModalType = { type: 'Opposing' }) => {
												setConnectionModal(event)
												setConnectionModalType(connectionModalType)
											}}
											handleClose={() => {
												setOpenedConnectionCard(false)
											}}
											connectionsArr={connectionsArr}
											connectionModalType={{
												type: node.__typename,
												recommendedDriverId: node.id,
											}}
											// recommendedDriver={node}
											referenceElement={graphData?.nodes[node.sourceNode.id]}
											sourceElement={graphData?.nodes[node.destNode.id]}
											// setCurrentDriverType={setCurrentDriverType}
										/>
									</NewIndividualConnectionWrapper>
								))}
							{connectionsArr?.all
								?.filter(node => node.structureTag)
								.map((element, index) => (
									<NewConnectionCard
										key={`${element?.id}-${index}`}
										id={element?.id}
										name={element?.name}
										type={element?.__typename}
										structureTag={element?.structureTag}
										beatsDriven={element?.beatsDriven}
										sourceNode={element?.sourceNode}
										description={element?.description}
										firstBeat={element?.firstBeat}
										disableHoverListener={true}
										createLog={createLog}
										handleClose={() => {}}
										sharedBeats={element?.sharedBeats}
										referenceElement={graphData?.nodes[element?.sourceNode?.id]}
										destNode={graphData.nodes[element?.destNode?.id]}
										isNewStructureTag={false}
										render={'driver'}
										isAddingExistingBeatVisible={false}
										parentLocation="Driver Card"
									/>
								))}
						</ConnectionElement>
					) : (
						<EmptyDriverWrapper extraPadding>
							<EmptyDriverDescription>{`No Relashionship Found!`}</EmptyDriverDescription>
						</EmptyDriverWrapper>
					)}
				</ConnectionDescription>
			)}
			{openedTab === 3 && (
				<div
					role="tabpanel"
					hidden={openedTab !== 3}
					id={`simple-tabpanel-${1}`}
					key={'beats'}
					aria-labelledby={`simple-tab-${1}`}
				>
					<BeatsContainer
						referenceElement={graphData?.nodes[id]}
						location={'Driver Card'}
						locationId={id}
						beatsList={beatsList}
						connectionTypeField={'driverConnections'}
					/>
				</div>
			)}
			{connectionModal && connectionModalType && (
				<CreateConnectionModal
					mainElementId={currentElementConnection?.id}
					mainElementType={currentElementConnection?.__typename}
					isOpenStructureTagModal={connectionModal}
					setStructureTagConnnectionModal={setConnectionModal}
					setConnectionModalType={setConnectionModalType}
					connectionModalType={connectionModalType}
					handleClose={({ id }) => {
						setConnectionModal(false)
					}}
					type={'visualization'}
					setIsNewStructureTag={() => {}}
				/>
			)}
		</DriverContainer>
	)
}

export default React.memo(DriverList)
