import React, { useState, useEffect, useCallback, useContext, useMemo, useRef } from 'react'
import FlexSearch from 'flexsearch'
import { setActiveConnections, setIsCatelogOpen, setSearchResult } from '../../state/actions'
import { sharedContext } from '../../state'
import { useUpdateNodeMutation } from '../../hooks'
import { ExitSearchIcon, IconWrapper, InputContainer, SearchInput } from './styled'
import searchIcon from '../../images/search-icon.svg'
import { debounce } from 'lodash'
import { updateCacheField } from '../../utils/apollo'
import { useApolloClient } from '@apollo/client'
import { nodeTypeForId } from '../../utils/utils'
import { useMediaQuery } from '@mui/material'
import { graphDataStateContext } from '../../state/graphDataProvider'
import { systemStateContext } from '../../state/systemProvider'

const SearchComponent = () => {
	const client = useApolloClient()
	const {
		state: { isCatelogOpen, activeConnections },
		dispatch: dispatchAction,
	} = useContext(sharedContext)
	const isSmallDevice = useMediaQuery('(max-width: 768px)')

	const {createLog} = useContext(systemStateContext)

	const [updateElement] = useUpdateNodeMutation(activeConnections?.elementType ?? 'Character')
	const [query, setQuery] = useState('')

	const graphData = useContext(graphDataStateContext)
	
	useEffect(() => {
		if (isCatelogOpen === false) {
			dispatchAction(setSearchResult(''))
			setQuery('')
			createLog("Catalog Closed",{},"Search","Search")

			
		} else if (isCatelogOpen) {
			createLog("Catalog Opened",{isCatelogOpen},"Search","Search")
			if (activeConnections?.isActive && activeConnections?.elementId && (graphData?.nodes?.[activeConnections?.elementId].lastDocView === 'full' )) {
				const updateData = {
					lastDocView: 'open',
					updatedAt: new Date().toISOString(),
				}
				updateCacheField(
					client,
					{
						id: activeConnections?.elementId,
						__typename: nodeTypeForId(activeConnections?.elementId),
					},
					updateData,
					true,
				)
				updateElement({
					variables: {
						id: activeConnections?.elementId,
						input: updateData
					},
				})
			}
		}
	}, [isCatelogOpen])

	const handleSearch = useRef(
		debounce(async searchterm => {
			createLog("Term Searched",{isCatelogOpen,queryLength:searchterm.length },"Search","Search")
			dispatchAction(setSearchResult(searchterm))
		}, 400),
	).current

	return (
		<InputContainer>
			<SearchInput
				type="text"
				placeholder={!isCatelogOpen? 'Open Catalog': 'Type Your Search'}
				value={isCatelogOpen ? query : ''}
				onChange={e => {
					setQuery(e.target.value)
					handleSearch(e.target.value)
				}}
				id="searchInput"
				onClick={e => {
					e.stopPropagation()
					dispatchAction(setIsCatelogOpen('Recent'))
				
					
				}}
			/>
			<IconWrapper>
				<img src={searchIcon} fill="black" />
			</IconWrapper>
			{isCatelogOpen ? (
				<ExitSearchIcon
					onClick={e => {
						e.stopPropagation()
						dispatchAction(setIsCatelogOpen(false))
						if(isSmallDevice){
							dispatchAction(
								setActiveConnections({
									isActive: null,
									elementId: null,
									elementType: null,
									docType: null,
									appView: null
								}),
							)
						}
					}}
				>
					{isSmallDevice ? 'X' : 'Close Catalog'}
				</ExitSearchIcon>
			) : (
				<></>
			)}
		</InputContainer>
	)
}

export default SearchComponent
