import gql from 'graphql-tag'
import {
	childConnectionsFragment,
	connectionsFragment,
	driverConnectionsFragment,
	nodeFieldsFragment,
	noteConnectionsFragment,
	RECOMMENDATION_FRAGMENT,
	staticConnectionsFragment,
	premiseConnectionsFragment,
	TASK_FRAGMENT,
} from '../../utils/fragment'

export const GET_ALL_NODES = gql`
	query getAllNodes($storyId: String) {
		nodes(storyId: $storyId) {
			id
			...nodeFields
			...beatConnections
			...driverConnections
			...childConnections
			...staticConnections
			...noteConnections
			...premiseConnections
		}
	}
	${nodeFieldsFragment}
	${connectionsFragment}
	${driverConnectionsFragment}
	${childConnectionsFragment}
	${staticConnectionsFragment}
	${noteConnectionsFragment}
	${premiseConnectionsFragment}
`

const TUTORIAL_FRAGMENT = gql`
	fragment tutorialFields on Tutorial {
		title
		id
		image
		message
		completeConditions {
			summaryLevel
			userFriendlyMetricName
			metric
			metricCurrentValue
			operation
			value
		}
		type
		timeToComplete
		difficulty
		valueProps
		status
		level
		order
		visibility
		updatedAt
	}
`

export const GET_CUSTOMER_PORTAL = gql`
	{
		createCustomerPortalSession
	}
`

export const GET_PROMOTION = gql`
	query getPromotion {
		promotion {
			title
			id
			image
			message
			percent_off
			updatedAt
		}
	}
`

export const GET_RECOMMENDATIONS = gql`
	query getRecommendations {
		recommendations {
			active_seen_opened {
				...recommendationFields
			}
			completed_rec {
				...recommendationFields
			}
			dismissed {
				...recommendationFields
			}
		}
	}
	${RECOMMENDATION_FRAGMENT}
`

export const GET_RELATIONSHIPS = gql`
	{
		relations {
			id
			connectionType
			structureTag
			arcStage
			description
			order
			relName
			beatsDriven
			arc {
				id
			}
			sourceNode {
				id
			}
			destNode {
				id
			}
			sharedBeats{
				id
			}
			firstBeat
			createdAt
			updatedAt
		}
	}
`

export const GET_TEMPLATES = gql`
  query GetTemplates {
    getTemplates {
      flexible {
        id
        milestoneBeats
        description
        name
        category
      }
      genreBased {
        id
        milestoneBeats
        description
        name
        category
      }
    }
  }
`

export const GET_TASKS = gql`
	query getTasks {
		tasks {
			...taskFields
		}
	}
	${TASK_FRAGMENT}
`

export const GET_USER = gql`
	{
		user {
			id
			name
			email
			isFirstLogin
			active
			currentSubgroups
			subscriptionStatus {
				editTextfield
				editNumberfield
				editDescriptionfield
				status
				daysLeft
				subscriptionSchedule
			}
			hash
			userSummary {
				storyCount
				exitOnboardingTourStage
				nextOnboardingTourStage
			}
			storySummary {
				beatConnectionCount
				staticConnectionCount
			}
		}
	}
`

export const GET_USER_STORIES = gql`
	{
		stories {
			id
			name
			description
			isDefault
			template
			summary {
				beatCount
				staticConnectionCount
				wordCount
				craftScore
				habitScore
				speedScore
			}
			lastDocView
            lastOpenedDriver
            lastOpenedChapter
			lastOpenedPremiseDriver
		}
	}
`

export const GET_VERSION = gql`
	{
		version
	}
`

export const GET_TUTORIALS = gql`
	query getTutorials {
		tutorials {
			id
			name
			description
			order
			tutorials {
				...tutorialFields
			}
			templateName
		}
	}
	${TUTORIAL_FRAGMENT}
`

export const GET_PREMISE_CONNECTIONS = gql`
	query PremiseConnections {
	    getPremiseConnections {
			existingConnections {
				id
			}
			missingConnections {
				sourceNodeId
				destNodeId
			}
		}
	}
`
