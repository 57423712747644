/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext, useRef } from 'react'

import {
	
	ScapBookLable,
	ScrapBookWrapper,

} from './styles'

import RelationshipList from '@lynit/layout/src/ui/DriverDoc/RelationshipList'

import { sharedContext } from '@lynit/shared/src/state/sharedProvider'

import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import {

	setNoElementError,
	setServerError,

} from '@lynit/shared/src/state/actions'

import { toastHandler } from '@lynit/shared/src/utils/backendHandler'


import DeepModeElement from '@lynit/deep-mode-element/src/ui/DeepModeElement'

import { useMemo } from 'react'
import { useCallback } from 'react'
import ThreePartDoc from '../ThreePartDoc'

const DriverDoc = ({
	id,
	types,
	setIsSetModifiers,
	setStructureTagConnnectionModal,
	structureTagConnnectionModal,
}) => {
	const graphData = useContext(graphDataStateContext)
	
	const {
		state: {
			
			activeConnections,
			deleteNode,
			
			currentStory,
		},
		dispatch: sharedDispatch,
	} = useContext(sharedContext)

	
	
	
	const [newNode, setNewNode] = useState(null)

	const serverErrorInterval = useRef()
	const [currentElement, setCurrentElement] = useState()

	const name = graphData?.nodes[id]?.name

	
	const BottomSectionInputComponent = useCallback(() => {

		return(<RelationshipList
			className="wewe"
			expandedElementId={id}
			key={id}
			id={id}
			type={graphData?.nodes[id]?.__typename}
			
			toastHandler={toastHandler}
			newNode={newNode}
			setNewNode={setNewNode}
			deleteNode={deleteNode}
			active={graphData?.nodes[id]?.__typename}
			setServerError={setServerError}
			serverErrorInterval={serverErrorInterval}
			setNoElementError={setNoElementError}
			structureTagConnnectionModal={structureTagConnnectionModal}
			setStructureTagConnnectionModal={setStructureTagConnnectionModal}
			setIsSetModifiers={setIsSetModifiers}
			currentElement={currentElement}
			setCurrentElement={setCurrentElement}
		/>)
	},[id,activeConnections?.elementId,graphData?.nodes?.[id]?.__typename ])
	
	const middleSectionDecriptionFields = {
		Character: [
			{ 
				label: 'Summary', 
				slug: 'description', 
				placeholder: `Who is ${name}? Consider describing them in their own words.`,
				aiRecommendations: false,
				coreElement: graphData.nodes[id]?.coreElement, },
			{ 
				label: 'Goals and Needs', 
				slug: 'goalsAndNeeds', 
				placeholder: `Goals are what ${name} pursues. Needs are what will truly make ${name} feel whole and complete.`, 
				aiRecommendations: false,
				coreElement: graphData.nodes[id]?.coreElement,},
			{ 
				label: 'Inner Conflict', 
				slug: 'innerConflict', 
				placeholder: `How do ${name}’s beliefs and behaviors get in the way of their needs? What life lesson does ${name} still have to learn in order to fulfill their need?`,
				aiRecommendations: false,
				coreElement: graphData.nodes[id]?.coreElement },
			{ 
				label: 'External Conflict', 
				slug: 'externalConflict', 
				placeholder: `Identify the external challenges, obstacles, and adversaries ${name} faces. These conflicts create tension and propel the plot forward as ${name} strives to overcome them.`,
				aiRecommendations: false,
				coreElement: graphData.nodes[id]?.coreElement, },
		],
		Arc: [
			{ 
				label: 'Summary', 
				slug: 'description', 
				placeholder: `In a sentence or two, describe what this Arc is about.`,
				aiRecommendations: false,
				coreElement: graphData.nodes[id]?.coreElement, },
			{ 
				label: 'Central Conflict', 
				slug: 'centralConflict', 
				placeholder: ` What is the main challenge, internal or external, that clash with the protagonist’s goal?`,
				aiRecommendations: false,
				coreElement: graphData.nodes[id]?.coreElement, },
		],
		Event: [
			{ 
				label: 'Summary', 
				slug: 'description', 
				placeholder: `Summarize ${name}.` ,
				aiRecommendations: false,
				coreElement: graphData.nodes[id]?.coreElement,}],
		Theme: [
			{ 
				label: 'Summary', 
				slug: 'description', 
				placeholder: `What's the central idea you want to engage with?`,
				aiRecommendations: false,
				coreElement: graphData.nodes[id]?.coreElement, },
			{ 
				label: 'Variations', 
				slug: 'variations', 
				placeholder: `What are different ways that you will engage with this theme? How can characters approach it differently?`,
				aiRecommendations: false,
				coreElement: graphData.nodes[id]?.coreElement, },
			{ 
				label: 'Moral Argument', 
				slug: 'moralArgument', 
				placeholder: `Readers don't like to have a theme stated directly. How will you work it organically into the story?`,
				aiRecommendations: false,
				coreElement: graphData.nodes[id]?.coreElement, },
			{ 
				label: 'Motifs', 
				slug: 'motifs', 
				placeholder: `How will you use motifs and symbols throughout the story to reinforce the theme?`,
				aiRecommendations: false,
				coreElement: graphData.nodes[id]?.coreElement, },
		],
		Setting: [
			{ 
				label: 'Summary', 
				slug: 'description', 
				placeholder: `Summarize ${name}. For real or imagined places, capture the general feel of the setting.`,
				aiRecommendations: false,
				coreElement: graphData.nodes[id]?.coreElement, },
			{ 
				label: 'Culture', 
				slug: 'culture', 
				placeholder: `What are the beliefs & traditions in ${name}? Who is considered the other or strange here?`,
				aiRecommendations: false,
				coreElement: graphData.nodes[id]?.coreElement, },
			{ 
				label: 'Society', 
				slug: 'society', 
				placeholder: `Define your people groups by how much power they have and how they wield it.`,
				aiRecommendations: false,
				coreElement: graphData.nodes[id]?.coreElement,},
			{ 
				label: 'Magic & Tech', 
				slug: 'magicTech', 
				placeholder: `How does the magic and technology work in ${name}?`,
				aiRecommendations: false,
				coreElement: graphData.nodes[id]?.coreElement, },
		],

	}

	const MiddleSectionInputComponent = useCallback(() => {

		return(
			<ScrapBookWrapper
			>
				{id && (
					<>
						<ScapBookLable>Scrapbook</ScapBookLable>
						<DeepModeElement
							id={id}
							placeholder={"Dump your freeform ideas here or organize them into Relationships and Beats below."}
						/>
					</>
				)}
			</ScrapBookWrapper>
		)


	},[id])

	// return useMemo(() => {
	return activeConnections?.isActive ? (
		<ThreePartDoc
		id={activeConnections?.elementId}
		docType={"Driver"}
		middleSectionDecriptionFields={middleSectionDecriptionFields[activeConnections.elementType]}
		MiddleSectionInputComponent={MiddleSectionInputComponent}
		BottomSectionInputComponent={BottomSectionInputComponent}
		/>
	) : (
		<></>
	)
	
}

export default React.memo(DriverDoc)
