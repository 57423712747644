/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext, useRef } from 'react'

import { useHistory } from 'react-router-dom'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import { userStateContext } from '@lynit/shared/src/state/userProvider'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'

import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import {

	useGetRelationshipsQuery,
	useLazyGetRelationshipsQuery,
} from '@lynit/shared/src/hooks'

import { useMemo } from 'react'
import { useCallback } from 'react'
import ThreePartDoc from '../ThreePartDoc'

//import StoryStructure from '../StoryStructure/StoryStructure'
import DriverList from '@lynit/shared/src/ui/DriverList'
import getConnections from '@lynit/shared/src/utils/getConnections'
import { DriverContainer, DriverLabel, PremiseDriver, PremiseRelation, PremiseWrapper } from '../PremisePlanner/style'
import AllRelationship from '@lynit/shared/src/ui/Search/AllRelationshipList'
import { analyticsDataStateContext } from '@lynit/shared/src/state/analyticsDataProvider'
import { orderBy } from 'lodash'
import { Tooltip } from '@mui/material'
import { ExtraBeatsContainer } from '../ThreePartDoc/styles'
import missingPremiseDriverIcon from '@lynit/shared/src/images/missing-premise-driver.svg'
import { getNodeIcon } from '@lynit/shared/src/utils/utils'
import { EmptyDriverDescription, EmptyDriverWrapper } from '../ListElement/styles'
import { BeatDescription } from '../DriverDoc/styles'
import CoachMatrics from '../StoryStructure/CoachMatrics'
import RadarChart from '@lynit/story-outline/src/ui/StoryOutline/RadarChart'


const OutliningPlanner = ({}) => {
	const graphData = useContext(graphDataStateContext)
	
	const { createLog, isMobile } = useContext(systemStateContext)
	const { data: relationship, loading: loadingRelationship } = useGetRelationshipsQuery({fetchPolicy: 'cache-only', component:"OutliningPlanner"})
	const {
			missingPremiseRelationships
			, premiseRingCompletionPct
			, milestoneBeatsCount
			, missingMilestoneBeatsCount
			, driversByDesignation
			, driversWithABeat
			, relationshipsWithABeat
			, secondaryDriversWithMileStoneBeat
			, minorDriversWithRelationship
			, relationshipsByType} = useContext(analyticsDataStateContext)
	const [premiseRelationshipList, setpremiseRelationshipList] = useState([])

	const outliningID = graphData.nodesByType["Outlining"]?.[0]?.id

// 	- [ ]  Drivers driving at least one beat out of drivers.
// - [ ]  Secondary Drivers should drive at least one milestone beat
// - [ ]  Relationships with at least one beat
// - [ ]  Minor Drivers with relationships out of total minor drivers count

	const metricsConfig = [
		{
			title: 'Drivers driving a Beat',
			description: 'Each Driver should drive at least one Beat',
			count: driversWithABeat.length,
			total: driversByDesignation.total.length,
		},
		{
			title: 'Secondary Drivers Driving a Milestone Beat',
			description: 'Each Secondary Driver should drive at least one Milestone Beat',
			count: secondaryDriversWithMileStoneBeat.length,
			total: driversByDesignation.secondary.length,
		},
		{
			title: 'Relationships used',
			description: 'Each Relationship should be captured by at least one Beat',
			count: relationshipsWithABeat.length,
			total: relationshipsByType.static.length,
		},
		{
			title: 'Minor Drivers with Relationships',
			description: 'Each Minor Driver should have by at least one Relationship',
			count: minorDriversWithRelationship.length,
			total: driversByDesignation.minor.length,
		},
	]

	const activeCoachConfig = {
		isActive: premiseRingCompletionPct === 1,
		progressPct: premiseRingCompletionPct > 0 ? premiseRingCompletionPct * 100 : 5,
		descriptionLabel: `Create ${missingPremiseRelationships.length} missing Premise Relationships and ${missingMilestoneBeatsCount} Milestone Beats to unlock Lynit Coach.`
	}

	const BottomSectionInputComponent = useCallback(() => {
		return  (
		<div>
			<CoachMatrics metricsConfig={metricsConfig} activeCoachConfig={activeCoachConfig} />
		
		</div>
		
		)
	}, [activeCoachConfig, metricsConfig ])


	const middleSectionDecriptionFields = {
		Premise: [
			{
				label: 'Summary',
				aiRecommendations: false,
				coreElement: 'true',
				slug: 'description',
				placeholder: `What is the story about? Summarize it in 2-3 sentences. Include the protagonist, the antagonist, the plot, and a hint of the theme.`,
			},
			{
				label: 'Narrative Drive',
				aiRecommendations: false,
				coreElement: 'true',
				slug: 'narrativeDrive',
				placeholder: `What drives the story? Why will the reader keep turning the page?`,
			},
		],
	}

	const MiddleSectionInputComponent = useCallback(() => {
		return (
			<PremiseWrapper>
				<DriverContainer>
					<DriverLabel>
						Secondary Drivers
						{ (<Tooltip
								title={'These are drivers that have a relationship to a Premise Driver.'}
								placement="top"
								arrow
								classes={{
									tooltip: {
										background: '#55534F',
									},
									arrow: {
										color: '#55534F',
									},
								}}
							>
								<ExtraBeatsContainer 
								style={{visibility:  driversByDesignation.secondary.length > 0 ? 'visible' : 'hidden', height: '16px'}} 
								isMulti>
									<p >
										{driversByDesignation.secondary.length||0}
									</p>
								</ExtraBeatsContainer>
							</Tooltip>)}
					</DriverLabel>
					<PremiseDriver>
						{driversByDesignation.secondary.length > 0 ? driversByDesignation.secondary.map((element, index) => {
							return (
								<DriverList
									key={element?.id}
									id={element?.id}
									name={element?.name}
									type={element?.__typename}
									description={element?.description}
									relationshipsCount={element?.staticConnections?.length}
									isDefaultOpened={element.source}
									render={'driver'}
								/>
							)
						}) : (
							<EmptyDriverWrapper extraPadding>
								<EmptyDriverDescription>{`No Secondary Drivers`}</EmptyDriverDescription>
							</EmptyDriverWrapper>
						)}
					</PremiseDriver>
				</DriverContainer>
				<DriverContainer>
				<DriverLabel>
						Minor Drivers
						{ (<Tooltip
								title={'These are drivers that have no relationship to a Premise Driver.'}
								placement="top"
								arrow
								classes={{
									tooltip: {
										background: '#55534F',
									},
									arrow: {
										color: '#55534F',
									},
								}}
							>
								<ExtraBeatsContainer 
								style={{visibility:  driversByDesignation.minor.length > 0 ? 'visible' : 'hidden', height: '16px'}} 
								isMulti>
									<p >
										{driversByDesignation.minor.length||0}
									</p>
								</ExtraBeatsContainer>
							</Tooltip>)}
					</DriverLabel>
					<PremiseDriver>
						{driversByDesignation.minor.length > 0 ? driversByDesignation.minor.map((element, index) => {
							return (
								<DriverList
									key={element?.id}
									id={element?.id}
									name={element?.name}
									type={element?.__typename}
									description={element?.description}
									relationshipsCount={element?.staticConnections?.length}
									isDefaultOpened={element.source}
									render={'driver'}
								/>
							)
						}) : (
							<EmptyDriverWrapper extraPadding>
								<EmptyDriverDescription>{`No Minor Drivers`}</EmptyDriverDescription>
							</EmptyDriverWrapper>
						)}
					</PremiseDriver>
				</DriverContainer>
			</PremiseWrapper>
		)
	}, [driversByDesignation.secondary.length,driversByDesignation.minor.length])

	// return useMemo(() => {
	return outliningID ? (
		<ThreePartDoc
		id={outliningID}
		docType={"Outlining"}
		middleSectionDecriptionFields={[]}
		MiddleSectionInputComponent={MiddleSectionInputComponent}
		BottomSectionInputComponent={BottomSectionInputComponent}
		docInstructionsText={"Secondary and Minor Drivers are variations, expansions, or tools for interrogating concepts defined in the Premise. Be careful of introducing completely new ideas here."}
		/>
	) : (
		<></>
	)
	
}

export default React.memo(OutliningPlanner)
