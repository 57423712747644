import { debounce } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useQuill } from 'react-quilljs'
import { autoSaveDispatchContext, setSavingData } from '../../state/autoSaveProvider'
import { useContext } from 'react'
import Quill from '../Quill/quill-lynit'
import 'quill/dist/quill.snow.css'
import { isJsonString, nodeTypeForId } from '../../utils/utils'
import { beatsDataStateContext } from '../../state/beatsProvider'
import { updateCacheField } from '../../utils/apollo'
import { useApolloClient } from '@apollo/client'
import { Fonts, Sizes } from '../../utils/utils'

const Delta = Quill.import('delta')

const useQuillAutoSave = (options, onTextChange, updateId, fieldName, initialText) => {
	const autoSaveDispatch = useContext(autoSaveDispatchContext) 
	const [quillInitialized,setQuillinitialized] = useState(false)
	//const { currentStoryId:storyId } = useContext(beatsDataStateContext)
	const client = useApolloClient()
	
	if(!updateId){
		
		throw new Error("Missing UpdateId for Quill")
	}
	if(!fieldName){
		throw new Error("Missing fieldName for Quill")
	}
	
	// const pastePlainTextHandler = (quill,range, clipText) => {
	// 		if (quill) {

	// 			quill.insertText(range?.index, clipText,Quill.sources.SILENT)

	// 			//scroll to visible the cursor
	// 			const selection = window.getSelection()
	// 			const firstRange = selection.getRangeAt(0)
	// 			const tempAnchorEl = document.createElement('br')
	// 			firstRange.insertNode(tempAnchorEl)
	// 			tempAnchorEl.scrollIntoView({
	// 				block: 'nearest',
	// 			})
	// 			tempAnchorEl.remove()
	// 			//selection.deleteFromDocument()

	// 			quill.setContents(quill.getContents(),Quill.sources.USER)
	// 			quill.setSelection(range?.index + clipText.length, Quill.sources.SILENT)

	// 			//this.quill.setSelection(range?.index + clipText.length, Quill.sources.SILENT)

	// 			return false
	// 		}
	// 	}
	

	const bindings = {
		enter: {
			key: "Enter",
			// format: { list: false },
			// eslint-disable-next-line
			handler: function (range, context) {
				
				if (range && this.quill ) {
				
					if(context.suffix.length >0){
						
						this.quill.insertText(range, '\n', context.format,Quill.sources.USER)
						this.quill.setSelection(range?.index+1, Quill.sources.SILENT)
						return
					}
					this.quill.insertText(range, '\n ', context.format,Quill.sources.USER)
					this.quill.setSelection(range?.index+1, Quill.sources.SILENT)
					
					
				}
			},
		},
		emDash: {
			key: '-',
			shiftKey: false,
			metaKey: false,
			//eslint-disable-next-line
			handler: function (range,context) {
				
				if (range && this.quill ) {
					if(context.prefix?.slice(-1) === "-"){

						this.quill.deleteText(range.index-1, 1,context.format,Quill.sources.SILENT)
						
						this.quill.insertText(range.index-1, '—', context.format,Quill.sources.USER)

						this.quill.setSelection(range?.index+1, Quill.sources.SILENT)
						return false
					}
					this.quill.insertText(range.index, '-', context.format,Quill.sources.USER)
					this.quill.setSelection(range?.index+1, Quill.sources.SILENT)
					
					
				}
				
			},
		},
		// shiftKey: {
		// 	key: 'V',
		// 	shiftKey: true,
		// 	metaKey: true,
		// 	//eslint-disable-next-line
		// 	handler: function (range) {
		// 		console.log("metaKey paste")
		// 		if (!navigator.clipboard) return

		// 		navigator.clipboard.readText().then(clipText => {
		// 			pastePlainTextHandler(this.quill, range, clipText)
		// 		})
				
		// 	},
		// },
		// shiftCtrlKey: {
		// 	key: 'V',
		// 	shiftKey: true,
		// 	ctrlKey: true,
		// 	//eslint-disable-next-line
		// 	handler: function (range) {
		// 		if (!navigator.clipboard) return
		// 		console.log("ctrlkey paste")
		// 		navigator.clipboard.readText().then(clipText => {
		// 			pastePlainTextHandler(this.quill, range, clipText)
		// 		})
		// 	},
		// },
	}
	
	const keyboard = {
		bindings,
	}

	const history = {
		delay: 1000,
		maxStack: 200,
		//userOnly: true,
	}

	const clipboard = {
		matchVisual: false,
		keepSelection: true,
		magicPasteLinks: true,
	}

	options.modules = {keyboard, history, clipboard,...options.modules}

	const change = new useRef(new Delta())


	const { quill, quillRef } = useQuill(options)

	const debounceFn = debounce(
		async (updateId,content, text) => {
			await onTextChange(updateId, content, text)
			autoSaveDispatch(setSavingData(false))
			change.current = new Delta()
		},
		500,
		{ maxWait: 1000 },
	)

	useEffect(() => {
		window.onbeforeunload = () => {
			if (change.current?.length() > 0) {
				return 'There are unsaved changes. Are you sure you want to leave?'
			}
		}
		
		
	}, [])

	const autoSave = async (updateId,delta, source, quill) => {
			if(source === 'user'){
			
			change.current = change.current?.compose(delta)
			if (change.current?.length() > 0) {
				autoSaveDispatch(setSavingData(true))
				debounceFn(updateId,JSON.stringify(quill.getContents()), quill.getText())
				
			}

			}
			
	}

	const selectText = e => {
		e.preventDefault()
		// remove selection of default selected options
		const qlPicker = e.target.closest('.ql-picker')
		if (qlPicker) {
			const qlPickerOptions = qlPicker.getElementsByClassName('ql-picker-options')?.[0]
			if (qlPickerOptions) {
				qlPickerOptions.childNodes.forEach(elem => {
					elem.classList.remove('ql-selected')
				})
			}
		}
	}

	const mouseDownHandler = e => {
		const dropdowns = Array.from(document.getElementsByClassName('ql-expanded'))
		let isDropDown = false
		dropdowns.forEach(dropdown => {
			if (dropdown.contains(e.target)) {
				isDropDown = true
			}
		})
		if (dropdowns.length && !isDropDown) {
			dropdowns.forEach(dropdown => {
				dropdown.classList.remove('ql-expanded')
			})
		}
		if (isDropDown && dropdowns.length > 1) {
			dropdowns.forEach(dropdown => {
				dropdown.classList.remove('ql-expanded')
				if (dropdown.contains(e.target)) {
					dropdown.classList.add('ql-expanded')
				}
			})
		}
	}

	useEffect(()=>{
		let needToUnMountCacheUpdate = true
		const cacheText = client?.cache?.data?.data[`${nodeTypeForId(updateId)}:${updateId}`]?.[fieldName]

		if(quill ){
			quill?.off('selection-change').on('selection-change',(range,oldRange, source)=>{
				//Handle onBlur
				if(!oldRange){
					quill.shouldAutoSave = true
					return
				}
				if (!range && JSON.stringify(quill?.getContents()) !== cacheText ) {
					const updateData = {}
					updateData[fieldName]= JSON.stringify(quill.getContents())
					updateData.updatedAt = new Date().toISOString()
					needToUnMountCacheUpdate = false
					updateCacheField(client,{id:updateId,__typename:nodeTypeForId(updateId)},updateData,true)
				}

				if(!range){
					quill.shouldAutoSave = false
					
				}
			})

		}

		
		return () => {
			if(!cacheText){
				return
			}
			if (!quill?.isSearchTextHighlighted && needToUnMountCacheUpdate && quill && (JSON.stringify(quill?.getContents()) !== cacheText && isJsonString(cacheText) || quill.getText() !== cacheText && !isJsonString(cacheText) ) && fieldName !=="firstBeat") {
				
				const updateData = {}
				updateData[fieldName]= JSON.stringify(quill?.getContents())
				updateData.updatedAt = new Date().toISOString()
				updateCacheField(client,{id:updateId,__typename:nodeTypeForId(updateId)},updateData,true)
			}
		}

	},[quill,initialText,updateId,fieldName,quillInitialized])

	useEffect(() => {
		if (quill && (quillInitialized !== updateId)) {

			setQuillinitialized(updateId)

			if(quillRef.current?.id !== updateId){

				throw new Error("Quill Component id Does Not Match")
			}

			if(quillRef.current?.dataset.fieldname !== fieldName){

				throw new Error("Quill Component data-fieldname Does Not Match")
			}
			
			

			quill?.off('text-change').on('text-change', (delta, _oldDelta, source) => {
				
				if(source === "initialUpload"|| _oldDelta?.ops?.[0]?.insert === delta.ops?.[0].insert && delta.ops?.[1]?.delete === delta.ops?.[0]?.insert?.length && source ==="api" && delta.ops?.length ===2) {
					//This stops us from saving when the component loads and just replaces the current text with the same text
					return
				}
				
				if (quill?.shouldAutoSave) {
					autoSave(updateId, delta, source, quill)
				}
			})


			quill.clipboard.addMatcher(Node.ELEMENT_NODE, function (node, copiedDelta) {
			
			const fontClassName = node.className?.split(" ")?.find(className => (className).includes("ql-font-"))?.replaceAll("ql-font-",'')
			const matchingClassNameFont = Fonts.find(font => font ===fontClassName )
			let font 
			if(matchingClassNameFont){
				font = matchingClassNameFont
			} else if (node.style.fontFamily ) {

				const nodeFonts = node.style?.fontFamily
				?.split(',')
				.map(f => f.trim().replaceAll(`"`, '').replaceAll(' ', '-'))
				
				font = nodeFonts.find(nf => {
					return Fonts.find(font => font === nf) 
				})

			} else {
			return copiedDelta

			}
			
			let newDelta = new Delta()
			const fontSize = node.style['font-size']

			
			copiedDelta.ops.forEach(op => {
				if(fontSize.includes('pt')){
					op.attributes = {
						...(op.attributes ?? {}),
						size: `${Math.round(fontSize.split('pt')[0] / 0.75)}px`,
						
					}
				}
				
				newDelta.insert(op.insert, {font,
					...(op.attributes ?? {}), 
					
				})
			})
			
			return newDelta
			
			})

			
		}
		//Set the quill contents and update the autosave function to reset the quill critical functions.
		
		if(quill ){

			const tempQuill = quill?.getSelection()
			//const cacheText = client?.cache?.data?.data[`${nodeTypeForId(updateId)}:${updateId}`]?.[fieldName]
			
			
			if (isJsonString(initialText)) {
				quill.setContents(JSON.parse(initialText),"initialUpload")
				if (tempQuill) {
					quill.setSelection(tempQuill)
				}
				
			} else {
				quill.setText(initialText||"","initialUpload")
			}

		}
		
	}, [quill, updateId, fieldName,quillInitialized, initialText])



	useEffect(() => {
			//does not deselect if user clicks on quill toolbar

			let toolbar
			if(options.modules.toolbar){
				toolbar = document.getElementById(options.modules.toolbar.substring(1))
			}
			
			if(toolbar && quill ){
				
				document.addEventListener('mousedown', mouseDownHandler)
				toolbar?.addEventListener('mousedown', selectText)
			}
			
			
			return () => {
				if(toolbar){
				
				toolbar?.removeEventListener('mousedown', selectText)
				document.removeEventListener('mousedown', mouseDownHandler)
				}
				
			}
	}, [quill])


	



	return { quill, quillRef }
}

export default useQuillAutoSave
