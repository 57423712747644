import styled from 'styled-components'
const StorySectionWrapper = styled.div``
const StoryHeader = styled.div`
	display: flex;
	align-items: center;
`
const StoryHeadingContainer = styled.div`
	margin-top: 20px;
`
const BackToTemplate = styled.div`
	font-family: Source Sans Pro;
	font-size: 10px;
	font-weight: 400;
	line-height: 12.57px;
	text-align: right;
	color: #babcbe;
	flex: 1;
	display: ${props => (props.visible ? 'flex' : 'none')};
	align-items: center;
	justify-content: end;
	gap: 5px;
`
const StoryLabel = styled.p`
	font-size: 20px;
	font-weight: 600;
	line-height: 25.14px;
	text-align: left;
	color: #4c4c4c;
	margin-bottom: 0;
	margin-top: 0;
`
const StoryDescription = styled.p`
	font-family: Source Sans Pro;
	font-size: 14px;
	font-weight: 600;
	line-height: 17.6px;
	text-align: left;
	color: rgba(186, 188, 190, 1);
	margin-top: 5px;
`
const StoryTemplate = styled.div`
	display: ${props => (props.visible ? 'flex' : 'none')};
	margin-bottom: 20px;
	flex-wrap: wrap;
	gap: 1rem;
`
const MainTemplate = styled.div`
	/* display: grid;
	grid-template-columns: 1fr 1fr; */
`
const TemplateLabel = styled.div`
	font-family: Source Sans Pro;
	font-size: 10px;
	font-weight: 600;
	line-height: 12.57px;
	text-align: left;
`
const StoryArea = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	gap: 3rem;
	margin-top: 20px;
`
const SubTemplate = styled.div``
const TemplateButton = styled.div`
	gap: 10px;
	border-radius: 4px;
	background: ${props => (props.isActive ? '#ffc300' : '#F5F5F5')};
	width: auto;
	padding: 7px 0;
	max-width: 80px;
	width: 80px;
	font-family: Source Sans Pro;
	font-size: 10px;
	font-weight: 400;
	line-height: 9px;
	text-align: center;
	color: ${props => (!props.isActive ? '#4C4C4C' : '#FFFFFF')};
	cursor: pointer;
`
const TemplateButtonWrapper = styled.div`
	display: flex;
	gap: 6px;
`
const LeftStoryPart = styled.div`
	display: flex;
	justify-content: center;
`
const CoachHeader = styled.div`
	display: flex;
	/* margin-bottom: 25px; */
	padding: 16px;
`
const CoachLabel = styled.div`
	font-family: Source Sans Pro;
	font-size: 16px;
	font-weight: 600;
	line-height: 20.11px;
`
const LynitCoachWrapper = styled.div`
	max-width: 266px;
	width: 100%;
	border: 1px solid #e8e8e8;
	border-radius: 4px;
	/* padding: 16px; */
	position: relative;
`
const MileStoneHeader = styled.div`
	display: flex;
	padding: 0 12px;
	gap: 10px;
`
const SelectTemplate = styled.div`
	padding: 16px 16px;
	background: #fffcf2;
	max-width: 208px;
	width: 100%;
	border-radius: 4px;
	box-sizing: border-box;
	display: ${props => (props.visible ? 'block' : 'none')};
	height: min-content;
`
const MileStoneDescription = styled.div`
	padding: 16px 16px;
	background: #fffcf2;
	color: #babcbe;
	font-family: Source Sans Pro;
	font-size: 12px;
	font-weight: 600;
	line-height: 15.08px;
	text-align: left;
`
const TemplateSelectionButton = styled.div`
	padding: 10px 0;
	background: #ffc300;
	color: white;
	font-family: Source Sans Pro;
	font-size: 14px;
	font-weight: 700;
	line-height: 12.6px;
	text-align: center;
	border-radius: 5px;
	cursor: pointer;
`
const MileStoneLabel = styled.div`
	font-family: Source Sans Pro;
	font-size: 14px;
	font-weight: 700;
	line-height: 17.6px;
	text-align: left;
	color: #babcbe;
`
const SummaryMetricsContainer = styled.div`
	display: flex;
	flex-direction: column;
	/* position: absolute; */
	/* top: 182px; */
	justify-content: center;
	align-content: center;
	gap: 24px;
	padding: 16px;
	filter: ${props => (props.visible ? 'unset' : 'blur(0.5rem)')};
`

const MilestoneBeatList = styled.div``
const MissingElementContainer = styled.div`
	//position: absolute;
	/* width: 194px;
	height: 60px; */
	//top: 198px;
	//left: 18px;

	.missingElementProgress {
		display: flex;

		.missingElementProgressItem {
			width: 100%;
		}
	}

	.missingElementText {
		font-family: Source Sans Pro;
		font-size: 10px;
		font-weight: 400;
		line-height: 12.57px;
		text-align: left;
`
const MissingConnectionSuggestion = styled.div`
	//position: absolute;
	width: 200px;
	height: ${({ missingConnSuggestion, missingElementSuggestion, missingBeatConnection }) =>
		missingElementSuggestion
			? '94px'
			: missingConnSuggestion
			? '88px'
			: missingBeatConnection
			? '103px'
			: '85px'};
	//top: 65px;
	background: #f3f4f5 !important;
	border-radius: 5px;
	border: 1px solid #e4e4e4;

	img {
		width: 200px;
	}
`

const MissingConnectionsContainer = styled.div`
	//position: absolute;
	//top: ${({ missingElementSuggestion }) => (missingElementSuggestion ? '368px' : '282px')};
	//left: 18px;

	.missingConneProgress {
		display: flex;

		.missingConnProgressItem {
			width: 100%;
		}
	}

	.missingConnectionText {
		font-family: Source Sans Pro;
		font-size: 10px;
		font-weight: 400;
		line-height: 12.57px;
		color: #000000;
		margin-top: 3px;
	}
`

const MissingBeatConnectionContainer = styled.div`
	//position: absolute;
	//top: ${({ missingElementSuggestion, missingConnSuggestion }) =>
		missingConnSuggestion || missingElementSuggestion ? '448px' : '370px'};
	//left: 18px;

	.missingConneProgress {
		display: flex;

		.missingConnProgressItem {
			width: 100%;
		}
	}

	.missingConnectionText {
		font-family: Source Sans Pro;
		font-size: 10px;
		font-weight: 400;
		line-height: 12.57px;
		color: #000000;
		margin-top: 3px;
	}
`

const Title = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 3px;
	.title {
		font-family: Source Sans Pro;
		font-size: 12px;
		font-weight: 600;
		line-height: 15.08px;
		text-align: left;

		color: #000000;
	}

	.status {
		font-family: Source Sans Pro;
		font-size: 12px;
		font-weight: 600;
		line-height: 15.08px;
		text-align: left;
		color: #000000;
	}

	.left {
		display: flex;
		align-items: center;
		gap: 7px;
	}

	.progressBar {
		position: absolute;
		left: 5px;
	}

	@media (max-width: 600px) {
		.title {
			font-size: 9px;
		}
		.status {
			font-size: 7px;
		}
		margin-bottom: 0;
	}
`

const SummaryCardSuggestion = styled.div`
	position: absolute;
	display: flex;
	width: 122px;
	height: 7px;
	top: 50px;
	left: 20px;
	background: #f3f4f5 !important;
	padding: 5px;
	border-radius: 5px;
	border: 1px solid #e4e4e4;
	cursor: pointer;

	.suggestionText {
		font-size: 9px;
		font-weight: 700;
		padding-right: 3px;
	}
`

const SummaryCardMissingBeatSuggestion = styled.div`
	position: absolute;
	display: flex;
	width: 90px;
	height: 7px;
	top: 50px;
	left: 20px;
	background: #f3f4f5 !important;
	padding: 5px;
	border-radius: 5px;
	border: 1px solid #e4e4e4;
	cursor: pointer;

	.suggestionText {
		font-size: 9px;
		font-weight: 700;
		padding-right: 3px;
	}
`

const SummaryCardWatchVideos = styled.div`
	position: absolute;
	bottom: ${({ missingElementSuggestion, missingBeatConnection }) =>
		missingElementSuggestion ? '90px' : missingBeatConnection ? '99px' : '84px'};
	left: ${({ missingBeatConnection }) => (missingBeatConnection ? '112px' : '142px')};
`

const CoachInitialSection = styled.div`
	position: absolute;
	top: 57%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 183px;
	width: 100%;
	text-align: center;
`
const FollowDescription = styled.div`
	margin: 30px auto 0 auto;
`
const DescriptionLable = styled.div`
	font-family: Source Sans Pro;
	font-size: 12px;
	font-weight: 700;
	line-height: 15.08px;
	text-align: center;
`
const DescriptionText = styled.div`
	font-family: Source Sans Pro;
	font-size: 12px;
	font-weight: 400;
	line-height: 15.08px;
	text-align: center;
`

export {
	StorySectionWrapper,
	SubTemplate,
	DescriptionText,
	DescriptionLable,
	FollowDescription,
	TemplateLabel,
	MainTemplate,
	StoryTemplate,
	StoryLabel,
	StoryHeader,
	StoryHeadingContainer,
	BackToTemplate,
	StoryDescription,
	TemplateButtonWrapper,
	TemplateButton,
	StoryArea,
	LeftStoryPart,
	MileStoneLabel,
	MileStoneHeader,
	SelectTemplate,
	TemplateSelectionButton,
	MileStoneDescription,
	LynitCoachWrapper,
	CoachHeader,
	CoachLabel,
	SummaryMetricsContainer,
	MissingElementContainer,
	MissingConnectionSuggestion,
	SummaryCardWatchVideos,
	SummaryCardMissingBeatSuggestion,
	SummaryCardSuggestion,
	Title,
	MissingBeatConnectionContainer,
	MissingConnectionsContainer,
	CoachInitialSection,
	MilestoneBeatList,
}
