import { useMutation } from '@apollo/client'
import { createNodeMutations } from '../data'
import { createCacheElement, deleteCacheElement } from '../utils/apollo';
import { toastHandler } from '../utils/backendHandler';
import { useApolloClient } from '@apollo/client'

export const useCreateNodeMutation = (key, options = {}) => {
	// let context = options.context
	// context ={...context, serializationKey: 'MUTATION'}
	// options = {...options,context}
	const [createNode, { data, loading, error }] = useMutation(createNodeMutations[key], options);
	const client = useApolloClient();
	
	const performCreateNodeMutation = async (
		{
			node,
			optimisticResponse,
			tempObj,
			hasOrder=true
		}
	) => {

		if (!node) {
			throw new Error('Missing Node for Create Node')
		}

		if (key !== "User")
			await createCacheElement(client, optimisticResponse, true, true)

		
		try {
			return await createNode({
				variables: {
					input: {
						...node
					},
				},
			})

		} catch (e) {

			if (key !== "User" && tempObj)
				deleteCacheElement(client, tempObj, hasOrder, true);

			if (e.message.includes('Upgrade')) {
				toastHandler('error', e.message, null, key, "Element Creation")

				if (
					user?.subscriptionStatus?.status === 'trialing' ||
					user?.subscriptionStatus?.status === 'active'
				) {
					window.location.reload()
				}
			} else {
				toastHandler('error', `There was an error creating the ${key}, try again.`, null, key, "Element Creation")
			}

			throw e
		}
	}
	return [performCreateNodeMutation, { data, loading, error }]
}
