import React, { useContext, useState, useEffect, useMemo, memo } from 'react'
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Interaction, Tooltip } from 'chart.js'
import { getRelativePosition } from 'chart.js/helpers'
import { Radar } from 'react-chartjs-2'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import { RadarChartContainer } from './styles'

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip)

const options = {
	scales: {
		r: {
			angleLines: {
				display: true,
			},
			suggestedMin: 0,
			suggestedMax: 10,
			ticks: {
				display: false,
				stepSize: 5,
			},
			pointLabels: {
				color: '#000',
				font: {
					size: 9,
				},
				padding: 3,
			},
		},
	},
	layout: {
		padding: {
			left: -12,
			right: -8,
		},
	},
	plugins: {
		tooltip: {
			mode: 'labels',
		}
	}
}

const pointInArea = (point, area) => (
	point.x >= area.left && point.x <= area.right &&
	point.y >= area.top && point.y <= area.bottom
);

Interaction.modes.labels = (chart, e, options, useFinalPosition) => {
	const position = getRelativePosition(e, chart)
	const scale = chart.scales.r
	const elements = []

	let dataIndex = null

	for (let i = 0; i < chart.data.labels.length; ++i) {
		const labelPos = scale._pointLabelItems[i]
		if (pointInArea(position, labelPos)) {
			dataIndex = i
			break
		}
	}

	if (dataIndex !== null) {
		for (let i = 1; i < chart.data.datasets.length; ++i) {
			const meta = chart.getDatasetMeta(i)
			elements.push({
				element: meta.data[dataIndex],
				datasetIndex: i,
				index: dataIndex,
			})
		}
	}

	return elements
}

let labelItems;

const RadarChart = () => {
	const { createLog } = useContext(systemStateContext)
	const { state:{currentStory} } = useContext(sharedContext)
	const initialScores = {
		craftScore: "1",
		habitScore: "1",
		speedScore: "1"
	}
	const [storyScore, setStoryScores] = useState(initialScores)

	useEffect(() => {
		if(currentStory?.summary) {
			const { craftScore, habitScore, speedScore } = currentStory.summary
			setStoryScores({
				craftScore: craftScore || '1',
				habitScore: habitScore || '1',
				speedScore: speedScore || '1'
			})
		}
	}, [currentStory])

	const data = useMemo(() => ({
		labels: ['Craft', 'Habit', 'Speed'],
		datasets: [
			{
				data: [10, 10, 10],
				backgroundColor: 'transparent',
				borderColor: '#000000',
				borderWidth: 1,
				pointBackgroundColor: '#000000',
				pointRadius: 2,
				pointHoverRadius: 2,
				animation: false
			},
			{
				data: [storyScore.craftScore, storyScore.habitScore, storyScore.speedScore],
				backgroundColor: '#41EAD41A',
				borderColor: '#41EAD4',
				borderWidth: 1,
				pointStyle: false,
				label: 'Score'
			},
		],
	}), [storyScore])

	return (
		<RadarChartContainer
			onMouseEnter={() => {
				createLog('Radar Chart Hovered', '{}', 'RadarChart', null)
			}}
		>
			<Radar
				data={data}
				options={options}
				plugins={[
					{
						beforeDraw: chart => {
							const { r } = chart.scales
							if (!labelItems) {
								r._pointLabelItems.forEach((item, index) => {
									const TRANSLATE_AMOUNT = 25
									if (index === 1) {
										item.x -= TRANSLATE_AMOUNT-4
										item.left -= TRANSLATE_AMOUNT-4
										item.right -= TRANSLATE_AMOUNT-4
									} else if (index === 2) {
										item.x += TRANSLATE_AMOUNT
										item.left += TRANSLATE_AMOUNT
										item.right += TRANSLATE_AMOUNT
									}
								})
								labelItems = r._pointLabelItems
							} else {
								r._pointLabelItems = labelItems
							}
						}
					},
				]}
			/>
		</RadarChartContainer>
	)
}

export default memo(RadarChart)
